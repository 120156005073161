import { getDocs, query, where } from "firebase/firestore";
import { pointageCollection, userCollection } from "./firebase";

const getNormalWork = async (code, hours, month) => {
  const date = getDateForPointage(month);

  const promises = hours.map(async (hour) => {
    const pointageQuery = query(
      pointageCollection,
      where("normalWork", "array-contains", {
        codeAffaire: code,
        hours: String(hour),
      }),
      where("verified", "==", true),
      where("date", ">=", new Date(date.start)),
      where("date", "<=", new Date(date.end))
    );
    const pointageSnapshot = await getDocs(pointageQuery);
    return pointageSnapshot.docs.map((doc) => doc.data());
  });
  const results = await Promise.all(promises);
  return results.flat(); // Flatten the array of arrays
};

const getExtraWork = async (code, hours, month) => {
  const date = getDateForPointage(month);

  const promises = hours.map(async (hour) => {
    const pointageQuery = query(
      pointageCollection,
      where("extraWork", "array-contains", {
        codeAffaire: code,
        hours: String(hour),
      }),
      where("verified", "==", true),
      where("date", ">=", new Date(date.start)),
      where("date", "<=", new Date(date.end))
    );
    const pointageSnapshot = await getDocs(pointageQuery);
    return pointageSnapshot.docs.map((doc) => doc.data());
  });
  const results = await Promise.all(promises);
  return results.flat(); // Flatten the array of arrays
};

const getPointage = async (codeAffaire, month) => {
  const normalWorkPromises = [];
  const extraWorkPromises = [];

  for (let i = 1; i <= 8; i++) {
    normalWorkPromises.push(getNormalWork(codeAffaire, [i], month));
  }

  for (let i = 1; i <= 16; i++) {
    extraWorkPromises.push(getExtraWork(codeAffaire, [i], month));
  }

  const normalWorkResults = await Promise.all(normalWorkPromises);
  const extraWorkResults = await Promise.all(extraWorkPromises);

  // return normalWorkResults.concat(extraWorkResults).flat(); // Concatenate and flatten the arrays
  const allResults = normalWorkResults.concat(extraWorkResults).flat();

  // Remove duplicates based on pointage_id
  const uniqueResults = [];
  const uniquePointageIds = new Set();
  allResults.forEach((result) => {
    if (!uniquePointageIds.has(result.pointage_id)) {
      uniqueResults.push(result);
      uniquePointageIds.add(result.pointage_id);
    }
  });

  return uniqueResults;
};

const getAgents = async (Ids) => {
  try {
    const agentsQuery = query(
      userCollection,
      where("user_id", "in", Ids),
      where("role", "==", "Agent")
    );

    const querySnapshot = await getDocs(agentsQuery);
    const agents = querySnapshot.docs.map((doc) => doc.data());

    return agents;
  } catch (error) {
    // console.log("Error in getting all agents getAllagents() :", error);
  }
};

const processData = async (data, Agents, codeAffaire) => {
  const respoGroup = localStorage.getItem("group_id");
  let res = [];
  let totalMonthHours = 0;
  let totalMonthPayment = 0;

  for (const ag of Agents) {
    const fullName = ag.fullName;
    const group_id = ag.group_id;
    let Normalwork = 0,
      Extrawork = 0,
      HolidayNormalwork = 0,
      HolidayExtrawork = 0,
      SundayNormalwork = 0,
      SundayExtrawork = 0,
      totalTrasportPrice = 0,
      totalDeplacementPrice = 0;

    for (const poi of data) {
      if (ag.user_id === poi.user_id) {
        const normalWorkObject = poi.normalWork.find(
          (obj) => obj.codeAffaire === codeAffaire
        );
        const extraWorkObject = poi.extraWork.find(
          (obj) => obj.codeAffaire === codeAffaire
        );

        if (poi.holiday === true) {
          if (normalWorkObject) {
            HolidayNormalwork += Number(normalWorkObject.hours);
          }
          if (extraWorkObject) {
            HolidayExtrawork += Number(extraWorkObject.hours);
          }
        } else if (isSunday(poi.date)) {
          if (normalWorkObject) {
            SundayNormalwork += Number(normalWorkObject.hours);
          }
          if (extraWorkObject) {
            SundayExtrawork += Number(extraWorkObject.hours);
          }
        } else {
          if (normalWorkObject) {
            Normalwork += Number(normalWorkObject.hours);
          }
          if (extraWorkObject) {
            Extrawork += Number(extraWorkObject.hours);
          }
        }

        totalTrasportPrice += Number(poi.transportPrice);
        totalDeplacementPrice += Number(poi.deplacementPrice);
      }
    }

    const pricePerHour = Number(ag.pricePerDay) / 8;
    const holidaysPrice =
      (HolidayNormalwork + HolidayExtrawork) * 2 * pricePerHour;
    const SundayPrice =
      (SundayNormalwork + SundayExtrawork) * 1.5 * pricePerHour;
    const NormalPrice = (Normalwork + Extrawork) * pricePerHour;

    const totalNormalwork = HolidayNormalwork + SundayNormalwork + Normalwork;
    const totalExtrawork = HolidayExtrawork + SundayExtrawork + Extrawork;

    let price = Number(
      holidaysPrice +
        SundayPrice +
        NormalPrice +
        totalTrasportPrice +
        totalDeplacementPrice
    );

    let obj = {
      fullName: fullName,
      group_id: group_id,
      totalNormalwork: totalNormalwork,
      totalExtrawork: totalExtrawork,
      totalTrasportPrice: totalTrasportPrice,
      totalDeplacementPrice: totalDeplacementPrice,
      totalHours: totalExtrawork + totalNormalwork,
      price: price.toFixed(2),
    };

    res.push(obj);

    totalMonthPayment += price;
    totalMonthHours += totalExtrawork + totalNormalwork;
  }

  res.sort((a, b) => b.totalHours - a.totalHours);

  let all = {
    totalMonthHours: totalMonthHours,
    totalMonthPayment: totalMonthPayment.toFixed(2),
    table: res,
  };

  return all;
};

function isSunday(timestamp) {
  const poiTimestamp =
    timestamp.seconds * 1000 + Math.round(timestamp.nanoseconds / 1e6);

  const date = new Date(poiTimestamp);
  return date.getDay() === 0; // Sunday is represented by 0 in JavaScript's Date object
}

const gettheData = async (codeAffaire, month) => {
  try {
    let emptyRes = {
      table: [],
      totalMonthHours: "",
      totalMonthPayment: "",
    };

    let Ids = [];

    let data = await getPointage(codeAffaire, month);
    data.map((d) => {
      let exist = false;
      Ids.map((id) => {
        if (id === d.user_id) exist = true;
      });
      if (!exist) Ids.push(d.user_id);
    });

    if (Ids.length === 0) return emptyRes;

    const Agents = await getAgents(Ids);
    const res = await processData(data, Agents, codeAffaire);

    return res;
  } catch (e) {
    console.error(e.message);
  }
};

function getDateForPointage(selectedMonth) {
  // console.log(selectedMonth)
  // Parse the selected month string, e.g., "2022-02"
  const [year, month] = selectedMonth.split("-");

  // Set the date to the first day of the selected month
  const startDate = new Date(parseInt(year), parseInt(month) - 1, 1);

  // Set the date to the last day of the selected month
  const endDate = new Date(parseInt(year), parseInt(month), 0);

  // Adjust to local time zone (GMT+1)
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59, 999);

  let date = {
    start: startDate,
    end: endDate,
  };

  return date;
}

export const getDataForYear = async (codeAffaire, year) => {
  try {
    let totalYearHours = 0;
    let totalYearPaymnet = 0;

    const months = [];
    for (let month = 1; month <= 12; month++) {
      months.push(`${year}-${month.toString().padStart(2, "0")}`);
    }

    const dataForYear = [];
    for (const month of months) {
      const dataForMonth = await gettheData(codeAffaire, month);
      if (dataForMonth.totalMonthHours !== "") {
        dataForYear.push({
          month,
          data: dataForMonth,
        });
      }
      totalYearHours += Number(dataForMonth.totalMonthHours);
      totalYearPaymnet += Number(dataForMonth.totalMonthPayment);
    }

    let yearSumup = { totalYearHours, totalYearPaymnet };

    // dataForYear.push({ yearSumup: yearSumup });

    // console.log(dataForYear)
    return { dataForYear, yearSumup };
  } catch (error) {
    console.error("Error in getDataForYear:", error);
    return [];
  }
};
