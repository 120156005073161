import React, {  useEffect, useState } from "react";
// Components :
import Sidebar from '../components/sidebar/Sidebar';
// import { PointageCongeMalade } from "../components/agentsPointageComponents/PointageCongeMalade";
// Material UI :
import {
    FormGroup,
    FormControlLabel,
    Checkbox,
    Tooltip,
    IconButton,
} from "@mui/material";
// React-select :
import Select from "react-select";
// Functions :
import { getAllAffairesForSelect } from "../config/affairesConfig";
import { addDoc, doc, getDocs, query, setDoc, where } from "firebase/firestore";
import { pointageCollection } from "../config/firebase";
import { getRespoAgentsNamdAndId } from "../config/agentsConfig";
// Icons :
import { AiOutlineDelete } from "react-icons/ai";
// Toast :
import { Toaster, toast } from 'sonner'
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// Date Picker :
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const Pointage = () => {
    const [doubleClick, setDoubleClick] = useState(false);
    const [agents, setAgents] = useState([]);
    const [affaires, setAffaires] = useState([]);
    // Tables :
    const [normalWorkTable, setNormalWorkTable] = useState([]);
    const [extraWorkTable, setExtraWorkTable] = useState([]);
    // Inputs :
    const [selectedAgents, setSelectedAgents] = useState([]);
    const [selectedAffaire, setSelectedAffaire] = useState();
    const [selectedHours, setSelectedHours] = useState("");
    const [selectedExtraAffaire, setSelectedExtraAffaire] = useState("");
    const [selectedExtraHours, setSelectedExtraHours] = useState("");

    // this foor inputs select cuz 'react-select' doesnt take the value it takes the hole obj
    const [showedAffaire, setShowedAffaire] = useState();
    const [showedHours, setShowedHours] = useState("");
    const [showedExtraAffaire, setShowedExtraAffaire] = useState("");
    const [showedExtraHours, setShowedExtraHours] = useState("");
    const [showedAgents, setShowedAgents] = useState([]);

    // Checkboxs :
    const [transportCheck, setTransportCheck] = useState(false);
    const [deplacementCheck, setDeplacementCheck] = useState(false);
    const [holidayCheck, setHolidayCheck] = useState(false);
    const [recuperationsCheck, setRecuperationsCheck] = useState(false);
    const [congeCheck, setCongeCheck] = useState(false);
    const [maladeCheck, setMaladeCheck] = useState(false);
    const [absentCheck, setAbsentheck] = useState(false);
    const [feteAidCheck, setfeteAidCheck] = useState(false);
    // Prices :
    const [transportPrice, setTrnaportPrice] = useState("");
    const [deplacementPrice, setDeplacementPrice] = useState("");
    // Message :
    const [message, setMessage] = useState("");
    // Total Hours :
    const [totalNormalHours, setTotalNormalHours] = useState(0);
    const [totalExtraHours, setTotalExtraHours] = useState(0);
    // Hours :
    const normalHours = [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
        { label: "8", value: "8" },
    ];
    const extraHours = [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
        { label: "8", value: "8" },
        { label: "9", value: "9" },
        { label: "10", value: "10" },
        { label: "11", value: "11" },
        { label: "12", value: "12" },
        { label: "13", value: "13" },
        { label: "14", value: "14" },
        { label: "15", value: "15" },
        { label: "16", value: "16" },
    ];

    // ? =========================|| Get Respo Agents Full name and ID ||==========================
    useEffect(() => {
        const handleGetAgents = async () => {
            const result = await getRespoAgentsNamdAndId();
            setAgents(result);
        };

        handleGetAgents();
    }, []);
    // ? ==========================================================================================

    // ? ==================================|| Get All Affaires ||==================================
    useEffect(() => {
        const handleGetAffaires = async () => {
            const result = await getAllAffairesForSelect();
            setAffaires(result);
        };

        handleGetAffaires();
    }, []);
    // ? ==========================================================================================

    // ? =====================================|| Checkboxs ||======================================
    const handleCheckboxChange = (n) => {
        if (n === 1) {
            setTransportCheck(!transportCheck);
        } else if (n === 2) {
            setDeplacementCheck(!deplacementCheck);
        } else if (n === 3) {
            setHolidayCheck(!holidayCheck);
        } else if (n === 4) {
            setRecuperationsCheck(!recuperationsCheck);
        } else if (n === 5) {
            setCongeCheck(!congeCheck);
        } else if (n === 6) {
            setMaladeCheck(!maladeCheck);
        } else if (n === 7) {
            setAbsentheck(!absentCheck);
        } else if (n === 8) {
            setfeteAidCheck(!feteAidCheck);
        }
    };
    // ? ==========================================================================================

    // ? ===============================|| Handle Selected Inputs ||===============================
    // const handleSelectedAgent = (e) => {
    //     console.log(e);
    //     setSelectedAgents(e.value);
    // };

    const handleSelectedAgent = (selectedOptions) => {
        // Extracting only the values from selected options
        const selectedValues = selectedOptions.map((option) => option.value);
        setSelectedAgents(selectedValues);
        setShowedAgents(selectedOptions);

        // Logging the selected values
        // console.log(selectedValues);
    };
    const handleSelectedAffaire = (e) => {
        setSelectedAffaire(e.value);
        setShowedAffaire(e);
    };
    const handleSelectedHours = (e) => {
        setSelectedHours(e.value);
        setShowedHours(e);
    };
    const handleSelectedExtraAffaire = (e) => {
        setSelectedExtraAffaire(e.value);
        setShowedExtraAffaire(e);
    };
    const handleSelectedExtraHours = (e) => {
        setSelectedExtraHours(e.value);
        setShowedExtraHours(e);
    };
    const handleTransportPrice = (e) => {
        setTrnaportPrice(e.target.value);
    };
    const handleDeplacementPrice = (e) => {
        setDeplacementPrice(e.target.value);
    };
    const handleMessage = (e) => {
        setMessage(e.target.value);
    };

    // ? ==========================================================================================

    // ? =======================================|| Toasts ||=======================================
    // const notifySuccess = (name) =>
    //     toast.success(`Vous avez pointé avec succès votre agent: ${name}.`, {
    //         position: "top-right",
    //         autoClose: 5000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: false,
    //         draggable: true,
    //         progress: undefined,
    //         theme: "colored",
    //     });
    // const notifyInfo = (message) =>
    //     toast.info(message, {
    //         position: "top-right",
    //         autoClose: 5000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: false,
    //         draggable: true,
    //         progress: undefined,
    //         theme: "colored",
    //     });
    // const notifyExist = (name, date) =>
    //     toast.info(`Votre agent : ${name} a déjà pointé pour ce jour: ${date}`, {
    //         position: "top-right",
    //         autoClose: 5000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: false,
    //         draggable: true,
    //         progress: undefined,
    //         theme: "colored",
    //     });
    // const notifyError = (message) =>
    //     toast.error(`${message}`, {
    //         position: "top-right",
    //         autoClose: 5000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: false,
    //         draggable: true,
    //         progress: undefined,
    //         theme: "colored",
    //     });
    // ? ==========================================================================================

    // ? ===============================|| Agent Pointage Handler ||===============================

    const pointageHandler = async (date, agentId) => {
        try {
            const startDate = new Date(date);
            startDate.setHours(0, 0, 0, 0);

            const endDate = new Date(date);
            endDate.setHours(23, 59, 59, 999);

            const pointageQuery = query(
                pointageCollection,
                where("user_id", "==", agentId),
                where("date", ">=", startDate),
                where("date", "<=", endDate)
            );

            const querySnapshot = await getDocs(pointageQuery);
            const pointage = querySnapshot.docs.map((doc) => doc.data());

            // If there is an object inserted within the last 24 hours for any selected agent, return 'Exist'

            //console.log(`pointage len ${pointage.length} in this date : ${date}`);

            if (pointage.length > 0) {
                //console.log("Exist");
                return "Exist";
            }

            //console.log("send");

            return "Send";
        } catch (error) {
            //console.log("error");
            // console.error(error);
            return false;
        }
    };

    const insertPointage = async () => {
        const group_id = localStorage.getItem("group_id");
        const respo_name = localStorage.getItem("respo_name");
        const dateArray = await getDatesInRange(startDate, endDate);

        if (doubleClick) {
            // Button is already disabled, ignore the click
            return;
        }

        setDoubleClick(true);
        let check = 0;

        selectedAgents.map(async (id) => {
            dateArray.map(async (da) => {
                const result = await pointageHandler(da, id);

                try {
                    if (
                        result === "Send" &&
                        (normalWorkTable.length > 0 ||
                            absentCheck ||
                            maladeCheck ||
                            congeCheck ||
                            recuperationsCheck ||
                            feteAidCheck ||
                            holidayCheck ||
                            (deplacementCheck && isSunday(da))) &&
                        dateArray.length !== 0
                    ) {
                        const pointageObj = {
                            user_id: id,
                            group_id: group_id,
                            verified: true,
                            verifiedBy: respo_name,
                            transport: transportCheck,
                            transportPrice: transportPrice,
                            deplacement: deplacementCheck,
                            deplacementPrice: deplacementPrice,
                            message: message,
                            holiday: holidayCheck,
                            feteAid: feteAidCheck,
                            recuperations: recuperationsCheck,
                            conge: congeCheck,
                            malade: maladeCheck,
                            absent: absentCheck,
                            normalWork: normalWorkTable,
                            extraWork: extraWorkTable,
                            totalNormalwork: totalNormalHours,
                            totalExtrawork: totalExtraHours,
                            totalHours: totalNormalHours + totalExtraHours,
                            date: new Date(da),
                        };

                        const point = await addDoc(pointageCollection, pointageObj);
                        await setDoc(
                            doc(pointageCollection, point.id),
                            { pointage_id: point.id },
                            { merge: true }
                        );

                        // Reset tables and other state after processing all selected agents
                        setNormalWorkTable([]);
                        setExtraWorkTable([]);
                        setSelectedAgents([]);
                        setSelectedAffaire("");
                        setSelectedHours("");
                        setSelectedExtraAffaire("");
                        setSelectedExtraHours("");
                        setTransportCheck(false);
                        setDeplacementCheck(false);
                        setHolidayCheck(false);
                        setRecuperationsCheck(false);
                        setCongeCheck(false);
                        setMaladeCheck(false);
                        setAbsentheck(false);
                        setTrnaportPrice("");
                        setDeplacementPrice("");
                        setMessage("");
                        setTotalNormalHours(0);
                        setTotalExtraHours(0);

                        setShowedAffaire("");
                        setShowedHours("");
                        setShowedExtraAffaire("");
                        setShowedExtraHours("");
                        setShowedAgents("");

                        check = 1;
                    } else if (result === "Exist") {
                        // notifyExist(getFullNameById(id), formatDate(da));
                        toast.info(`Votre agent : ${getFullNameById(id)} a déjà pointé pour ce jour: ${formatDate(da)}`)
                        check = 2;
                    } else {
                        // notifyInfo(
                        //     "vous devez sélectionner le projet et les heures travaillées, sauf si c'est un dimanche"
                        // );
                        toast.info("vous devez sélectionner le projet et les heures travaillées, sauf si c'est un dimanche")
                        check = 3;
                    }
                } catch (error) {
                    // notifyError("Il y a un problème, veuillez réessayer");
                    toast.warning('Il y a un problème, veuillez réessayer !')
                } finally {
                    setDoubleClick(false);
                }
            });
            if (check === 1) toast.success(`Vous avez pointé avec succès votre agent: ${getFullNameById(id)}.`) // notifySuccess(getFullNameById(id));
        });
    };

    function isSunday(dateString) {
        console.log(dateString);
        const date = new Date(dateString);
        return date.getDay() === 0; // 0 represents Sunday
    }

    const getDatesInRange = (startDate, endDate) => {
        try {
            const dateArray = [];
            let currentDate = new Date(startDate);
            const end = endDate === null ? new Date(currentDate) : new Date(endDate);

            while (currentDate <= end) {
                dateArray.push(new Date(currentDate));
                currentDate.setDate(currentDate.getDate() + 1);
            }

            //console.log("array date:", dateArray);

            return dateArray;
        } catch (error) {
            //console.log("error in getDateRange()");
            throw error; // Rethrow the error so that it can be caught by the caller if needed.
        }
    };

    const getFullNameById = (userId) => {
        const user = agents.find((user) => user.value === userId);
        return user ? user.label : "";
    };

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");

        return `${day}-${month}-${year}`;
    };

    // ? ==========================================================================================

    // ? ===================================|| Handle Tables ||====================================
    const handleNormalHoursAdd = () => {
        try {
            if (selectedAffaire !== "" && selectedHours !== "") {
                const newData = { codeAffaire: selectedAffaire, hours: selectedHours };
                setTotalNormalHours(totalNormalHours + Number(selectedHours));
                setNormalWorkTable([...normalWorkTable, newData]);

                // Reset inputs:
                setShowedAffaire("");
                setShowedHours("");
            }
            if (selectedAffaire === "" && selectedHours === "") {
                // notifyInfo(
                //     "Vous devez sélectionner une affaire et les heures de l'affaire"
                // );
                toast.info("Vous devez sélectionner une affaire et les heures de l'affaire")
            }
            if (selectedAffaire === "" && selectedHours !== "") {
                // notifyInfo("Vous devriez sélectionner une affaire");
                toast.info("Vous devriez sélectionner une affaire")
            }
            if (selectedAffaire !== "" && selectedHours === "") {
                // notifyInfo("Vous devez sélectionner les heures d'affaire");
                toast.info("Vous devez sélectionner les heures d'affaire")
            }
        } catch (error) {
            // notifyError("Il y a un problème, veuillez réessayer");
            toast.warning('Il y a un problème, veuillez réessayer !')
        }
    };
    const handleExtraHoursAdd = () => {
        try {
            if (selectedExtraAffaire !== "" && selectedExtraHours !== "") {
                const newData = {
                    codeAffaire: selectedExtraAffaire,
                    hours: selectedExtraHours,
                };
                setTotalExtraHours(totalExtraHours + Number(selectedExtraHours));
                setExtraWorkTable([...extraWorkTable, newData]);

                // Reset inputs:
                setShowedExtraAffaire("");
                setShowedExtraHours("");
            }
            if (selectedExtraAffaire === "" && selectedExtraHours === "") {
                // notifyInfo(
                //     "Vous devez sélectionner une affaire et les heures de l'affaire"
                // );
                toast.info("Vous devez sélectionner une affaire et les heures de l'affaire")
            }
            if (selectedExtraAffaire === "" && selectedExtraHours !== "") {
                // notifyInfo("Vous devriez sélectionner une affaire");
                toast.info("Vous devriez sélectionner une affaire")
            }
            if (selectedExtraAffaire !== "" && selectedExtraHours === "") {
                // notifyInfo("Vous devez sélectionner les heures d'affaire");
                toast.info("Vous devez sélectionner les heures d'affaire")
            }
        } catch (error) {
            // notifyError("Il y a un problème, veuillez réessayer");
            toast.warning('Il y a un problème, veuillez réessayer !')
        }
    };
    // ? ==========================================================================================

    // ? =============================|| Handel Delete From Table ||===============================
    const handleDeleteNormalWork = (index, hours) => {
        const updatedNormalWorkTable = [...normalWorkTable];

        updatedNormalWorkTable.splice(index, 1);

        setNormalWorkTable(updatedNormalWorkTable);

        setTotalNormalHours(totalNormalHours - hours);
    };
    const handleDeleteExtraWork = (index, hours) => {
        const updatedExtraWorkTable = [...extraWorkTable];

        updatedExtraWorkTable.splice(index, 1);

        setExtraWorkTable(updatedExtraWorkTable);

        setTotalExtraHours(totalExtraHours - hours);
    };
    // ? ==========================================================================================
    // const [startDate, setStartDate] = useState(new Date());
    // const [endDate, setEndDate] = useState(new Date());

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };
    // ? ==========================================================================================

    return (
        <div className="flex gap-2 md:gap-3 lg:gap-5 bg-[#F4F9FF]">
            <Sidebar />
            <main className="flex-1 mx-auto py-4 pr-4 h-screen overflow-y-scroll">
                {/* Toast */}
                {/* <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    limit={1}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover={false}
                    theme="light"
                /> */}

                <Toaster expand={true} richColors />

                {/* Title & Add Agent Button */}
                <div className="flex flex-wrap justify-between items-center mb-10">
                    <p className="text-2xl md:text-3xl lg:text-4xl font-bold">Pointage</p>
                    {/* <div className="w-full lg:w-1/2 flex justify-end mt-4 lg:mt-0">
                        <PointageCongeMalade />
                    </div> */}
                </div>

                <div className="w-full mt-8">
                    {/* Agent & Dates */}
                    <div className="w-full p-2">
                        <div className="bg-white p-4 shadow-gray shadow-sm rounded-lg w-full">
                            <p className="lg:text-2xl text-lg font-bold mb-4">
                                Équipe et date
                            </p>
                            <div className="flex flex-wrap justify-between items-center">
                                {/* Agent */}
                                <div className="w-full lg:w-1/2 pr-2">
                                    <p className="font-medium mb-1">Équipe</p>
                                    <Select
                                        className="w-full rounded-md"
                                        showSearch
                                        placeholder="Select a person"
                                        optionFilterProp="children"
                                        onChange={handleSelectedAgent}
                                        value={showedAgents}
                                        options={agents}
                                        mode="multiple"
                                        isClearable={true}
                                        isSearchable={true}
                                        isMulti
                                    />
                                </div>
                                {/* Dates */}
                                <div className="w-full lg:w-1/2 mt-4 md:mt-0">
                                    <p className="font-medium mb-1">La date</p>
                                    <DatePicker
                                        wrapperClassName="datePicker"
                                        className="w-full rounded-md border border-gray py-1 px-3"
                                        selected={startDate}
                                        onChange={onChange}
                                        startDate={startDate}
                                        endDate={endDate}
                                        selectsRange
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Normal Wrok */}
                    <div className="w-full p-2">
                        <div className="bg-white p-4 shadow-gray shadow-sm rounded-lg">
                            <p className="lg:text-2xl text-lg font-bold mb-4">
                                Travail normal
                            </p>
                            {/* Project */}
                            <div className="mb-3">
                                <p className="mb-1">Selectionez le projet</p>
                                <Select
                                    className="w-full rounded-md P-2"
                                    size="large"
                                    showSearch
                                    placeholder="Selectionez le projet"
                                    optionFilterProp="children"
                                    onChange={handleSelectedAffaire}
                                    value={showedAffaire}
                                    options={affaires}
                                    dropdownStyle={{ overflowX: "scroll" }}
                                />
                            </div>

                            {/* Hours */}
                            <div className="mb-6">
                                <p className="mb-1">Sélectionnez les heures</p>
                                <Select
                                    className="w-full rounded-md P-2"
                                    size="large"
                                    showSearch
                                    placeholder="Sélectionnez les heures"
                                    optionFilterProp="children"
                                    onChange={handleSelectedHours}
                                    value={showedHours}
                                    options={normalHours}
                                />
                            </div>

                            {/* Button */}
                            <div className="w-full flex justify-end mt-4">
                                <button
                                    className={`${totalNormalHours + Number(selectedHours) > 8
                                        ? "bg-gray cursor-not-allowed"
                                        : "bg-primary"
                                        } px-6 py-2 rounded-md text-white`}
                                    onClick={() => handleNormalHoursAdd()}
                                    disabled={
                                        totalNormalHours + Number(selectedHours) > 8 ? true : false
                                    }
                                >
                                    Ajouter
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Extra Wrok */}
                    <div className="w-full p-2">
                        <div className="bg-white py-4 px-4 shadow-gray shadow-sm rounded-lg">
                            <p className="lg:text-2xl text-lg font-bold mb-4">
                                Heures supplémentaires
                            </p>

                            {/* Project */}
                            <div className="mb-3">
                                <p className="mb-1">Selectionez le projet</p>
                                <Select
                                    className="w-full rounded-md P-2"
                                    size="large"
                                    showSearch
                                    placeholder="Selectionez le projet"
                                    optionFilterProp="children"
                                    onChange={handleSelectedExtraAffaire}
                                    value={showedExtraAffaire}
                                    options={affaires}
                                />
                                {/* <Select
                                    options={affaires}
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={handleSelectedExtraAffaire}
                                /> */}
                            </div>

                            {/* Hours */}
                            <div className="mb-6">
                                <p className="mb-1">Sélectionnez les heures</p>
                                <Select
                                    className="w-full rounded-md P-2"
                                    size="large"
                                    showSearch
                                    placeholder="Sélectionnez les heures"
                                    optionFilterProp="children"
                                    onChange={handleSelectedExtraHours}
                                    value={showedExtraHours}
                                    options={extraHours}
                                />
                                {/* <Select
                                    options={extraHours}
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={handleSelectedExtraHours}
                                /> */}
                            </div>

                            {/* Button */}
                            <div className="w-full flex justify-end mt-4">
                                <button
                                    className="bg-primary px-6 py-2 rounded-md text-white"
                                    onClick={() => handleExtraHoursAdd()}
                                >
                                    Ajouter
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Deplacement & Trnasport & Holiday & Valider Button */}
                <div className="bg-white rounded-lg p-4 mt-4">
                    <FormGroup>
                        {/* Déplacement */}
                        <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center mb-2">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={deplacementCheck}
                                        onChange={() => handleCheckboxChange(2)}
                                    />
                                }
                                label="Déplacement"
                                labelPlacement="end"
                            />
                            <input
                                type="number"
                                value={deplacementPrice}
                                onChange={handleDeplacementPrice}
                                disabled={!deplacementCheck}
                                placeholder="Entrez le prix"
                                className="w-1/2 md:w-1/3 lg:w-1/4 px-2 py-2 rounded-md border border-gray"
                            />
                        </div>

                        {/* Trnasport */}
                        <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center mb-2">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={transportCheck}
                                        onChange={() => handleCheckboxChange(1)}
                                    />
                                }
                                label="Transport"
                                labelPlacement="end"
                            />
                            <input
                                type="number"
                                value={transportPrice}
                                onChange={handleTransportPrice}
                                disabled={!transportCheck}
                                placeholder="Entrez le prix"
                                className="w-1/2 md:w-1/3 lg:w-1/4 px-2 py-2 rounded-md border border-gray"
                            />
                        </div>

                        {/* Holiday */}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={holidayCheck}
                                    onChange={() => handleCheckboxChange(3)}
                                />
                            }
                            label="Jour férié"
                            labelPlacement="end"
                        />

                        {/* Conge */}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={congeCheck}
                                    onChange={() => handleCheckboxChange(5)}
                                />
                            }
                            label="Congé"
                            labelPlacement="end"
                        />

                        {/* Malade */}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={maladeCheck}
                                    onChange={() => handleCheckboxChange(6)}
                                />
                            }
                            label="Malade"
                            labelPlacement="end"
                        />

                        {/* Absent  */}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={absentCheck}
                                    onChange={() => handleCheckboxChange(7)}
                                />
                            }
                            label="Absent(e)"
                            labelPlacement="end"
                        />

                        {/* feteAid  */}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={feteAidCheck}
                                    onChange={() => handleCheckboxChange(8)}
                                />
                            }
                            label="fête aïd"
                            labelPlacement="end"
                        />

                        {/* Message */}
                        <div className="mt-2">
                            <p className="font-normal mb-1">Message</p>
                            <input
                                type="text"
                                value={message}
                                onChange={handleMessage}
                                placeholder="Entrez votre message ..."
                                className="w-full px-2 py-2 rounded-md border border-gray mr-2"
                            />
                        </div>
                    </FormGroup>

                    {/* Valider Button */}
                    <div className="flex justify-end mt-4">
                        <button
                            className={`${doubleClick ? "bg-gray cursor-not-allowed" : "bg-primary"
                                } px-6 py-2 rounded-md text-white`}
                            onClick={() => insertPointage()}
                            disabled={doubleClick}
                        >
                            Valider
                        </button>
                    </div>
                </div>

                {/* Normal Work Table */}
                {normalWorkTable && normalWorkTable.length > 0 ? (
                    <div className="mt-4">
                        <p className="lg:text-3xl text-xl font-extrabold mb-2">
                            Travail normal
                        </p>
                        <table className="w-full overflow-hidden rounded-t-lg">
                            <thead className="bg-primary text-white py-2">
                                <tr>
                                    <th className="py-3 px-3 text-sm lg:text-base border border-white">
                                        Affaire
                                    </th>
                                    <th className="py-3 px-3 text-sm lg:text-base border border-white">
                                        Heures
                                    </th>
                                    <th className="py-3 px-3 text-sm lg:text-base border border-white">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white">
                                {normalWorkTable.map((data, index) => (
                                    <tr key={index}>
                                        <td className="border border-gray text-center py-2 px-3 text-sm lg:text-base">
                                            {affaires.map((aff) => {
                                                if (data.codeAffaire === aff.value) {
                                                    return <p key={aff.value}>{aff.label}</p>;
                                                }
                                                return null;
                                            })}
                                        </td>
                                        <td className="border border-gray text-center py-2 px-3 text-sm lg:text-base">
                                            {data.hours}
                                        </td>
                                        <td className="border border-gray text-center py-2 px-3 text-sm lg:text-base">
                                            <div className="flex items-center justify-center cursor-pointer">
                                                <Tooltip
                                                    title="Supprimer"
                                                    onClick={() =>
                                                        handleDeleteNormalWork(index, data?.hours)
                                                    }
                                                >
                                                    <IconButton>
                                                        <AiOutlineDelete size={20} color="red" />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : null}

                {/* Extra Work Table */}
                {extraWorkTable && extraWorkTable.length > 0 ? (
                    <div className="mt-4">
                        <p className="lg:text-3xl text-xl font-extrabold mb-2">
                            Heures supplémentaires
                        </p>
                        <table className="w-full overflow-hidden rounded-t-lg">
                            <thead className="bg-primary text-white py-2">
                                <tr>
                                    <th className="py-3 px-3 text-sm lg:text-base border border-white">
                                        Affaire
                                    </th>
                                    <th className="py-3 px-3 text-sm lg:text-base border border-white">
                                        Heures supplémentaires
                                    </th>
                                    <th className="py-3 px-3 text-sm lg:text-base border border-white">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white">
                                {extraWorkTable.map((data, index) => (
                                    <tr key={index}>
                                        <td className="border border-gray text-center py-2 px-3 text-sm lg:text-base">
                                            {affaires.map((aff) => {
                                                if (data.codeAffaire === aff.value) {
                                                    return <p key={aff.value}>{aff.label}</p>;
                                                }
                                                return null;
                                            })}
                                        </td>
                                        <td className="border border-gray text-center py-2 px-3 text-sm lg:text-base">
                                            {data.hours}
                                        </td>
                                        <td className="border border-gray text-center py-2 px-3 text-sm lg:text-base">
                                            <div className="flex items-center justify-center cursor-pointer">
                                                <Tooltip
                                                    title="Supprimer"
                                                    onClick={() =>
                                                        handleDeleteExtraWork(index, data?.hours)
                                                    }
                                                >
                                                    <IconButton>
                                                        <AiOutlineDelete size={20} color="red" />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : null}
            </main>
        </div>
    );
};
