import React, { useEffect, useState } from 'react';
// Material UI :
import { Box, Modal } from '@mui/material';
// Toast :
import { toast } from 'sonner';
import 'react-toastify/dist/ReactToastify.css';
// Functions :
import { addAffaire } from '../../config/affairesConfig';
// Assets : 
import Loading from '../../assets/images/white-loading.gif';

export const AddAffaireModal = ({ onAdd }) => {
    const [codeAffaire, setCodeAffaire] = useState('');
    const [nomAffaire, setNomAffaire] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState('');

    // ? ===============================|| Modal Style ||===============================
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [width, setWidth] = useState('50%');
    const [padding, setPadding] = useState(3);

    const currentWidth = window.innerWidth;

    useEffect(() => {
        if (currentWidth <= 500) {
            setWidth('90%');
            setPadding(2);
        }
    }, [currentWidth]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width,
        maxHeight: '95vh',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: padding,
        borderRadius: 3
    };
    // ? ===============================================================================

    // ? ===============================|| Toast ||===============================
    // const notifySuccess = () => toast.success('L\'affaire a été ajouté avec succès !', {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: false,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "colored",
    // });
    // const notifyError = () => toast.error('Il y a un problème, réessayez s\'il vous plaît', {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: false,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "colored",
    // });
    // const notifyInfo = (message) => toast.info(`${message}`, {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: false,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "colored",
    // });
    // ? ===============================================================================

    // ? ===============================|| Add Affaire ||===============================
    const handleAddAffaire = () => {
        try {
            setIsLoading(true);
            if (nomAffaire !== '' && codeAffaire !== '' && selectedDate !== '') {
                addAffaire(codeAffaire, nomAffaire, selectedDate).then((result) => {
                    if (result === '0') {
                        // notifyInfo('Code affaire existe déjà, veuillez réessayer !');
                        toast.info('Code affaire existe déjà, veuillez réessayer !')
                        setIsLoading(false);
                    }
                    if (result === '1') {
                        // notifyInfo('Nom d\'affaire existe déjà, veuillez réessayer !');
                        toast.info('Nom d\'affaire existe déjà, veuillez réessayer !')
                        setIsLoading(false);
                    }
                    if (result === '2') {
                        onAdd(); // Refresh table after adding an affair.
                        // notifySuccess();
                        toast.success('L\'affaire a été ajouté avec succès')
                        handleClose();
                        // * Reset Code and name affaire inputs
                        setCodeAffaire('')
                        setNomAffaire('');
                        setIsLoading(false);
                    }
                    if (result === '3') {
                        // notifyError();
                        toast.warning('Il y a un problème, réessayez s\'il vous plaît !')
                        handleClose();
                        setIsLoading(false);
                    }
                })
            } else {
                setIsLoading(false);
                // handleClose();
                // notifyInfo('Veuillez remplir le formulaire');
                toast.info('Veuillez remplir le formulaire !')
            }
        } catch (error) {
            setIsLoading(true);
            // notifyError();
            toast.warning('Il y a un problème, réessayez s\'il vous plaît !')
            handleClose();
            setIsLoading(false);
        }
    };
    // ? ===============================================================================

    // ? ============================|| Handle Enter Click ||===========================
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleAddAffaire();
        }
    };
    // ? ===============================================================================

    // ? ===============================|| Format Date ||===============================
    useEffect(() => {
        // Set the default date to today
        const today = new Date();
        const year = today.getFullYear();
        let month = today.getMonth() + 1; // Months are zero-based
        let day = today.getDate();

        // Format month and day with leading zeros if needed
        if (month < 10) {
            month = `0${month}`;
        }
        if (day < 10) {
            day = `0${day}`;
        }

        const formattedDate = `${year}-${month}-${day}`;

        // Set the default date
        setSelectedDate(formattedDate);
    }, []);
    // ? ===============================================================================

    return (
        <div>
            <button
                className='border border-primary py-2 px-6 rounded-md text-primary hover:bg-primary hover:text-white transition-all'
                onClick={handleOpen}
            >
                Ajouter Affaire
            </button>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <p className='text-3xl font-bold'>Ajouter Affaire</p>
                    <div className='mt-10'>
                        {/* Date Creation */}
                        <div className='mb-4'>
                            <p className='font-medium mb-2'>La Date</p>
                            <input
                                type='date'
                                className='border border-gray w-full rounded-md py-2 px-3'
                                value={selectedDate}
                                onChange={e => setSelectedDate(e.target.value)}
                                onKeyPress={handleKeyPress}
                            />
                        </div>

                        {/* Code Affaire */}
                        <div className='mb-4'>
                            <p className='font-medium mb-2'>Code Affaire</p>
                            <input
                                type='text'
                                placeholder='eg: 123456'
                                className='border border-gray w-full rounded-md py-2 px-3'
                                value={codeAffaire}
                                onChange={e => setCodeAffaire(e.target.value)}
                                onKeyPress={handleKeyPress}
                            />
                        </div>

                        {/* Name Affaire */}
                        <div className='mb-8'>
                            <p className='font-medium mb-2'>Nom d'Affaire</p>
                            <input
                                type='text'
                                placeholder="eg: nom d'affaire"
                                className='border border-gray w-full rounded-md py-2 px-3'
                                value={nomAffaire}
                                onChange={e => setNomAffaire(e.target.value)}
                                onKeyPress={handleKeyPress}
                            />
                        </div>

                        {/* Buttons */}
                        <div className='flex justify-end items-center'>
                            <button
                                onClick={handleClose}
                                className='border border-gray rounded-md py-2 px-4 mr-4'
                            >
                                Annuler
                            </button>
                            <button
                                onClick={() => handleAddAffaire()}
                                className='bg-primary text-white rounded-md py-2 px-4'
                            >
                                {
                                    isLoading ? <img src={Loading} alt='' className='w-6' /> : <p>Ajouter</p>
                                }
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}