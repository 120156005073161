import React, { useEffect, useState } from 'react';
// Material UI :
import { Box, Modal, IconButton, Tooltip } from '@mui/material';
// Toast :
import { toast } from 'sonner';
// Icons : 
import { AiOutlineDelete } from 'react-icons/ai';
import { deleteRespo } from '../../config/responsablesConfig';
// Functions :
// import { deleteAffaire } from '../../config/affairesConfig';

export const DeleteRespoModal = ({ respoId, respoName, onDelete }) => {
    // ? ==========================|| Modal Style ||==========================
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [width, setWidth] = useState('50%');
    const [padding, setPadding] = useState(3);

    const currentWidth = window.innerWidth;

    useEffect(() => {
        if (currentWidth <= 500) {
            setWidth('90%');
            setPadding(2);
        }
    }, [currentWidth]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width,
        maxHeight: '95vh',
        background: 'white',
        borderRadius: 3,
        boxShadow: 24,
        p: padding,
    };
    // ? =====================================================================

    // ? ==========================|| Toast ||==========================
    const notifySuccess = (messsage) => toast.success(messsage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
    const notifyError = (messsage) => toast.error(messsage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
    // ? =====================================================================

    // ? ==========================|| Delete Affaire ||==========================
    const handleDeleteAffaire = async () => {
        try {
            const result = await deleteRespo(respoId);
            if (result === 'deleted') {
                onDelete(); // Refresh table after deleting an affair
                // notifySuccess('Responsable supprimé avec succès');
                toast.success('Responsable supprimé avec succès')
                handleClose();
            } else if (result === 'notDeleted') {
                // notifyError('Veuillez réessayer');
                toast.warning('Il y a un problème, veuillez réessayer !');
                handleClose();
            }
        } catch (error) {
            // notifyError('Veuillez réessayer');
            toast.warning('Il y a un problème, veuillez réessayer !');
            handleClose();
        }
    };
    // ? ========================================================================

    return (
        <div>

            <Tooltip title="Supprimer" onClick={handleOpen}>
                <IconButton>
                    <AiOutlineDelete size={20} color='red' />
                </IconButton>
            </Tooltip>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <p className='text-3xl font-bold'>Supprimer Responsable</p>
                    <div className='mt-6'>
                        <p className='text-base lg:text-lg mb-6'>Êtes-vous sûr de vouloir supprimer ce responsable <b>{respoName}</b> ?</p>
                        <div className='flex justify-end items-center'>
                            <button
                                onClick={handleClose}
                                className='border border-gray rounded-md py-2 px-4 mr-4'
                            >
                                Annuler
                            </button>

                            <button
                                onClick={() => handleDeleteAffaire()}
                                className='bg-primary text-white rounded-md py-2 px-4'
                            >
                                Oui, je suis sûr
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}