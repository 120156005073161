import { Query, addDoc, doc, getDocs, query, setDoc, where } from "firebase/firestore";
import { groupsCollection } from "./firebase";

export const getGroups = async () => {
    try {

        const querySnapshot = await getDocs(groupsCollection);
        const groups = querySnapshot.docs.map((doc) => {
            const { group_name, group_id, shortName } = doc.data();
            return { label: group_name, value: group_id, shortName: shortName };
        });


        return groups;
    } catch (error) {
        return [];
    }
};

export const getAllGroups = async () => {
    try {

        const querySnapshot = await getDocs(groupsCollection);
        const groups = querySnapshot.docs.map((doc) => doc.data());


        groups.sort((a, b) => {
            const groupIdA = parseInt(a.group_id);
            const groupIdB = parseInt(b.group_id);
            return groupIdA - groupIdB;
        });

        return groups;
    } catch (error) {
        return [];
    }
};

export const editGroup = async (id, shortName, group_name) => {
    try {
        let newData = {
            shortName: shortName,
            group_name: group_name,

        };

        await setDoc(doc(groupsCollection, id), newData, { merge: true });

        return 'Changed';
    } catch (error) {
        return 'notChanged';
    }
};

export const addGroup = async (groupId, shortName, group_name) => {
    try {
        const groupQuery = query(
            groupsCollection,
            where('group_id', '==', groupId)
        );
        const group = (await getDocs(groupQuery)).docs.map((doc) => doc.data());

        //console.log("group :", group)

        if (group.length === 0) {
            let data = {
                group_id: groupId,
                group_name: group_name,
                shortName: shortName,
            };

            const groupRef = await addDoc(groupsCollection, data);
            await setDoc(doc(groupsCollection, groupRef.id), { GroupId: groupRef.id }, { merge: true });

            return 'Created'
        }

        return "Exist"
    } catch (error) {
        return "Exist"
    }
}