import React, { useEffect, useState } from 'react';
// Material UI :
import { Box, Modal, IconButton, Tooltip } from '@mui/material';
// Toast :
import { toast } from 'sonner';
// Icons : 
import { AiOutlineDelete } from 'react-icons/ai';
// Functions :
import { deleteAffaire } from '../../config/affairesConfig';
// Assets : 
import Loading from '../../assets/images/white-loading.gif';

export const DeleteAffaireModal = ({ id, name, onDelete }) => {
    const [isLoading, setIsLoading] = useState(false);

    // ? ==========================|| Modal Style ||==========================
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [width, setWidth] = useState('50%');
    const [padding, setPadding] = useState(3);

    const currentWidth = window.innerWidth;

    useEffect(() => {
        if (currentWidth <= 500) {
            setWidth('90%');
            setPadding(2);
        }
    }, [currentWidth]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width,
        maxHeight: '95vh',
        background: 'white',
        borderRadius: 3,
        boxShadow: 24,
        p: padding,
    };
    // ? =====================================================================

    // ? ==========================|| Toast ||==========================
    // const notifySuccess = () => toast.success('L\'affaire a été supprimer avec succès !', {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: false,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "colored",
    // });
    // const notifyError = () => toast.error('Il y a un problème, réessayez s\'il vous plaît', {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: false,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "colored",
    // });
    // ? =====================================================================

    // ? ==========================|| Delete Affaire ||==========================
    const handleDeleteAffaire = async () => {
        try {
            setIsLoading(true);
            await deleteAffaire(id);
            onDelete() // Refresh table after deleting an affair
            // notifySuccess();
            toast.success('L\'affaire a été supprimer avec succès')
            handleClose();
            setIsLoading(false);
        } catch (error) {
            // notifyError();
            toast.warning('Il y a un problème, réessayez s\'il vous plaît !')
            handleClose();
            setIsLoading(false);
        }
    };
    // ? ========================================================================

    // ? =========================|| Handle Enter Click ||=======================
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleDeleteAffaire();
        }
    };
    // ? ========================================================================

    return (
        <div onKeyPress={handleKeyPress}>
            <Tooltip title="Supprimer" onClick={handleOpen}>
                <IconButton>
                    <AiOutlineDelete size={20} color='red' />
                </IconButton>
            </Tooltip>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <p className='text-3xl font-bold'>Supprimer Affaire</p>
                    <div className='mt-6'>
                        <p className='text-base lg:text-lg mb-6'>Etes-vous sûr de vouloir supprimer cette affaire <b>{name}</b> ?</p>
                        <div className='flex justify-end items-center'>
                            <button
                                onClick={handleClose}
                                className='border border-gray rounded-md py-2 px-4 mr-4'
                            >
                                Annuler
                            </button>

                            <button
                                onClick={() => handleDeleteAffaire()}
                                className='bg-primary text-white rounded-md py-2 px-4'
                            >
                                {
                                    isLoading ? <img src={Loading} alt='' className='w-6' /> : <p>Oui, je suis sûr</p>
                                }
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}