import React, { useEffect, useState } from 'react';
// Packages :
import { Skeleton } from '@mui/material';
// Icons :
import { BsDatabaseSlash } from 'react-icons/bs';
// Toast :
import { Toaster } from 'sonner'
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// Components :
import Sidebar from '../components/sidebar/Sidebar';
import { AddRespoModal } from '../components/responsablesComponents/AddRespoModal';
import { DeleteRespoModal } from '../components/responsablesComponents/DeleteRespoModal';
import { EditRespoModal } from '../components/responsablesComponents/EditRespoModal';
import { Pagination } from '../components/paginationComponent/Pagination';
import SearchBar from '../components/searchBar/SearchBar';
// Functions :
import { getRespos } from '../config/responsablesConfig';
import { getGroups } from '../config/groups';

export const Responsables = () => {
    const [respos, setRespos] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState(respos);
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(false);

    // ? ==========================|| Get All Responsables ||==========================
    const handleGetRespos = async () => {
        try {
            setLoading(true);
            await getRespos().then((result) => {
                setRespos(result);
                setFilteredData(result);
            });
            setLoading(false);
        } catch (error) {
            return [];
        }
    };

    useEffect(() => {
        handleGetRespos();
    }, []);
    // ? ==============================================================================

    // Refresh table :
    const handleAddResponsable = async () => {
        handleGetRespos();
    };
    const handleEditRespo = async () => {
        handleGetRespos();
    };
    const handleDeleteRespo = async () => {
        handleGetRespos();
    };

    // ? ===============================|| Pagination ||===============================
    const itemsPerPage = 10; // Set the number of items per page
    const [currentPage, setCurrentPage] = useState(1);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // Assuming respoAffaires is your data array
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    // ? ==============================================================================

    // ? =================|| Cpitalize the first letter of the name ||=================
    const capitalizeFirstLetter = (str) => {
        return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    };
    // ? ==============================================================================

    // ? =========================|| Handle Search Function ||=========================
    const handleSearch = (searchTerm) => {
        setSearchQuery(searchTerm);

        // If the search query is empty, display all respos
        if (searchTerm.trim() === "") {
            setFilteredData(respos);
        } else {
            // Otherwise, filter the data based on the search term
            const searchTermLower = searchTerm.toLowerCase();

            const filteredResults = respos.filter((agent) => {
                const groupID = agent.group_id;
                const shortName = getShortName(groupID);

                // Check for an exact match in the agent name or group short name
                return (
                    agent.fullName.toLowerCase().includes(searchTermLower) ||
                    shortName.toLowerCase().includes(searchTermLower)
                );
            });

            setCurrentPage(1);
            setFilteredData(filteredResults);
        }
    };
    // ? ==============================================================================

    // ? ============================|| get the shortName ||===========================
    useEffect(() => {
        const handleGetGroups = async () => {
            const result = await getGroups();
            setGroups(result);
        };

        handleGetGroups();
    }, []);

    const getShortName = (groupId) => {
        const group = groups.find((group) => group.value === groupId);
        return group ? group.shortName : "-";
    };
    // ? ==============================================================================

    return (
        <div className="flex gap-2 md:gap-3 lg:gap-5 bg-[#F4F9FF]">
            <Sidebar />
            <main className="flex-1 mx-auto py-4 pr-4 h-screen overflow-y-auto">
                {/* <ToastContainer
                    position="top-right"
                    autoClose={1000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover={false}
                    theme="colored"
                /> */}

                <Toaster expand={true} richColors />

                <div className='flex flex-wrap justify-between items-center mb-10'>
                    <p className='text-3xl lg:text-4xl font-bold'>Responsables</p>
                    <AddRespoModal onAdd={() => handleAddResponsable()} />
                </div>

                {/* Search Bar */}
                <SearchBar onSearch={handleSearch} placeholder='Recherche par nom de responsable ou par groupe' />

                <p className='text-gray text-sm mb-2'>Ici vous pouvez trouver les responsables et vous pouvez ajouter, modifier et supprimer un responsable.</p>
                <div className='overflow-x-scroll'>
                    <table className='w-full overflow-hidden rounded-t-lg'>
                        <thead className='bg-primary text-white py-2'>
                            <tr>
                                <td className='py-3 text-center text-sm lg:text-base border border-white font-bold'>Groupe</td>
                                <td className='py-3 text-center text-sm lg:text-base border border-white font-bold'>Nom et prénom</td>
                                <td className='py-3 text-center text-sm lg:text-base border border-white font-bold'>E-mail</td>
                                <td className='py-3 text-center text-sm lg:text-base border border-white font-bold'>Action</td>
                            </tr>
                        </thead>
                        <tbody className='bg-white'>
                            {loading ? (
                                <tr>
                                    <td
                                        colSpan={4}
                                    >
                                        <Skeleton animation="wave" width="98%" height={50} className='mx-auto' />
                                        <Skeleton animation="wave" width="98%" height={50} className='mx-auto' />
                                        <Skeleton animation="wave" width="98%" height={50} className='mx-auto' />
                                        <Skeleton animation="wave" width="98%" height={50} className='mx-auto' />
                                        <Skeleton animation="wave" width="98%" height={50} className='mx-auto' />
                                        <Skeleton animation="wave" width="98%" height={50} className='mx-auto' />
                                    </td>
                                </tr>
                            ) :
                                currentItems.length > 0 ? (
                                    currentItems.map((respo, i) => (
                                        <tr key={i}>
                                            <td className={`border border-gray text-center py-2 px-3 text-sm lg:text-base`}><b>{getShortName(respo?.group_id)}</b></td>
                                            <td className={`border border-gray text-center py-2 px-3 text-sm lg:text-base  min-w-[200px]`}>{capitalizeFirstLetter(respo?.fullName)}</td>
                                            <td className={`border border-gray text-center py-2 px-3 text-sm lg:text-base`}>{respo?.email}</td>
                                            <td className={`border border-gray text-center py-2 px-3 text-sm lg:text-base`}>
                                                <div className='flex items-center justify-center'>
                                                    <EditRespoModal
                                                        respoId={respo?.user_id}
                                                        respoName={respo?.fullName}
                                                        respoEmail={respo?.email}
                                                        respoGroupId={respo?.group_id}
                                                        onEdit={() => handleEditRespo()}
                                                        page={currentPage}
                                                        searchQuery={searchQuery}
                                                    />
                                                    <DeleteRespoModal
                                                        respoId={respo?.user_id}
                                                        respoName={respo?.fullName}
                                                        onDelete={() => handleDeleteRespo()}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    ))) : (
                                    <tr>
                                        <td colSpan="4" className="text-center py-4 text-gray">
                                            <div className='flex flex-col justify-center items-center mt-4'>
                                                <BsDatabaseSlash size={30} className='mb-2' />
                                                <p>Pas de données disponibles.</p>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
                <Pagination
                    itemsPerPage={itemsPerPage}
                    data={filteredData}
                    onPageChange={handlePageChange}
                    currentPage={currentPage}
                />
            </main>
        </div>
    )
}