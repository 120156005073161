import React, { useEffect, useState } from 'react';
// Icons :
import { RiLockPasswordLine } from "react-icons/ri";
// Toast :
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Packages :
import { Box, Modal } from '@mui/material';
import { getDocs, query, setDoc, where } from 'firebase/firestore';
import { auth, userCollection } from '../../config/firebase';
import { updatePassword } from 'firebase/auth';
// Firebase :
// import { getDocs, query, setDoc, where } from 'firebase/firestore';
// import { auth, userCollection } from '../../config/firebase';
// import { updatePassword } from 'firebase/auth';

const ChangePasswordModal = () => {
    // Inputs :
    const [newPassword, setNewPassword] = useState('');
    const [newConfirmedPassword, setNewConfirmedPassword] = useState('');
    // Errors :
    const [error1, setError1] = useState('');
    const [error2, setError2] = useState('');

    // ? ==============================|| Modal Style ||==============================
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [width, setWidth] = useState('50%');
    const [padding, setPadding] = useState(3);

    const currentWidth = window.innerWidth;

    useEffect(() => {
        if (currentWidth <= 500) {
            setWidth('90%');
            setPadding(2);
        }
    }, [currentWidth]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width,
        background: 'white',
        borderRadius: 3,
        boxShadow: 24,
        p: padding,
    };
    // ? =============================================================================

    // ? ============================|| Handle Inputs ||============================== 
    const handleNewPassword = (e) => {
        setNewPassword(e.target.value);
    };
    const handleNewConfirmedPassword = (e) => {
        setNewConfirmedPassword(e.target.value);
    };
    // ? =============================================================================

    // ? ================================|| Tostify ||================================
    const notifySuccess = (message) => toast.success(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
    // ? =============================================================================

    // ? ===============|| Update Password From (Firestore Database) ================= 
    const updateUserPassword = async (newPassword) => {
        const id = localStorage.getItem('id');
        const q = query(
            userCollection,
            where('user_id', '==', id)
        );
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0];

            const pass = {
                hashedPassword: newPassword,
                passwordChanged: true
            }

            // Update the password field in the user document
            await setDoc(userDoc.ref, pass, { merge: true });
        } else { }
    };
    // ? =============================================================================

    // ? =================|| Change Password From (Authentication) ||================= 
    const changePassword = async (newPassword) => {
        const user = auth.currentUser;

        // Update the user's password
        updatePassword(user, newPassword);
    };
    // ? =============================================================================

    // ? ====================|| Check if passwords are matched ||=====================
    const handleChangePassword = async () => {
        if (newPassword.length < 6) {
            setError1('Le mot de passe doit contenir au moins 6 caractères');
            setError2('');
        } else if (newPassword.length >= 6 && newPassword !== newConfirmedPassword) {
            setError1('');
            setError2('les mot de passe ne son pas les mêmes !!');
        } else if (newPassword === newConfirmedPassword) {
            changePassword(newPassword);
            updateUserPassword(newPassword);

            setError1(''); // Reset Error 1
            setError2(''); // Reset Error 2
            setNewPassword(''); // Reset Fields
            setNewConfirmedPassword(''); // Reset Fields
            handleClose(); // Close Modal
            notifySuccess('Le mot de passe a été changé avec succès');
        }
    };
    // ? =============================================================================

    return (
        <div>
            {/* Change Password Button */}
            <div className='mt-4 lg:mt-0 flex justify-end'>
                <button
                    className='flex items-center bg-primary text-white font-medium py-2 px-4 rounded-md'
                    onClick={handleOpen}
                >
                    <RiLockPasswordLine size={20} color='white' className='mr-4' />
                    Changer le mot de passe
                </button>
            </div>

            {/* Modal */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='flex flex-col justify-center items-center'>
                        <RiLockPasswordLine size={100} color='#458EEF' className='mb-4' />
                        <p className='text-2xl lg:text-3xl font-bold mb-6 text-center'>Changer le mot de passe</p>
                    </div>

                    {/* New Password */}
                    <div className='mb-4 mt-6'>
                        <p className='mb-1'>Nouveau mot de passe</p>
                        <input
                            type="password"
                            placeholder='***********'
                            className='w-full border border-gray px-4 py-2 rounded-md'
                            autoFocus
                            value={newPassword}
                            onChange={handleNewPassword}
                        />
                    </div>

                    {/* Confirm New Password */}
                    <div className='mb-6'>
                        <p className='mb-1'>Confirmez mot de passe</p>
                        <input
                            type="password"
                            placeholder='***********'
                            className='w-full border border-gray px-4 py-2 rounded-md'
                            value={newConfirmedPassword}
                            onChange={handleNewConfirmedPassword}
                        />
                    </div>

                    <p className='text-red font-bold text-center mb-6'>{error1}</p>
                    <p className='text-red font-bold text-center mb-6'>{error2}</p>

                    <div className='flex justify-end'>
                        <button
                            className='py-2 px-6 lg:px-8 rounded-md text-primary border border-primary hover:bg-light transition-all mr-4'
                            onClick={handleClose}
                        >
                            Annuler
                        </button>
                        <button
                            className='py-2 px-6 lg:px-8 rounded-md text-white bg-primary'
                            onClick={() => handleChangePassword()}
                        >
                            Changer
                        </button>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default ChangePasswordModal;