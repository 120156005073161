import React, { useEffect, useState } from 'react';
// Packages :
import { motion } from 'framer-motion';
import { useMediaQuery } from 'react-responsive'
import { NavLink, useLocation } from 'react-router-dom';
// Icons :
import { AiOutlineUser, AiOutlineFileText, AiOutlineLogout } from "react-icons/ai";
import { IoIosArrowBack } from 'react-icons/io';
import { LiaUsersSolid, LiaUserClockSolid, LiaUserCogSolid, LiaUserEditSolid } from "react-icons/lia";
import { MdMenu, MdOutlineWorkHistory } from "react-icons/md";
import { LuLayoutDashboard, LuUsers2 } from "react-icons/lu";
import { AiOutlineDollar } from "react-icons/ai";
// Images :
import Logo from '../../assets/images/logo.png';

const Sidebar = () => {
    // const links = [
    //     { name: "Aperçu", link: "/apercu", icon: <LuLayoutDashboard size={23} className='min-w-max' /> },
    //     { name: "Affaires", link: "/affaires", icon: <AiOutlineFileText size={23} className='min-w-max' /> },
    //     { name: "Affaires Historique", link: "/affaires-historique", icon: <MdOutlineWorkHistory size={23} className='min-w-max' /> },
    //     { name: "Groupes", link: "/groups", icon: <LiaUsersSolid size={24} className='min-w-max' /> },
    //     { name: "Responsables", link: "/responsables", icon: <LiaUserCogSolid size={23} className='min-w-max' /> },
    //     { name: "Pointage", link: "/pointage", icon: <LiaUserEditSolid size={23} className='min-w-max' /> },
    //     { name: "Agents", link: "/agents", icon: <LuUsers2 size={23} className='min-w-max' /> },
    //     { name: "Historique", link: "/agents-historique", icon: <LiaUserClockSolid size={23} className='min-w-max' /> },
    //     { name: "Paiement", link: "/paiement", icon: <AiOutlineDollar size={23} className='min-w-max' /> },
    //     { name: "Profil", link: "/profil", icon: <AiOutlineUser size={23} className='min-w-max' /> },
    //     { name: "Se déconnecter", link: "/", icon: <AiOutlineLogout size={23} className='min-w-max' />, margin: true, onClick: () => logout() },
    // ];

    const links = [
        {
            section: "MENU GÉNÉRAL",
            links: [
                { name: "Aperçu", link: "/apercu", icon: <LuLayoutDashboard size={20} className='min-w-max' /> },
            ]
        },
        {
            section: "AFFAIRES",
            links: [
                { name: "Affaires", link: "/affaires", icon: <AiOutlineFileText size={20} className='min-w-max' /> },
                { name: "Affaires Historique", link: "/affaires-historique", icon: <MdOutlineWorkHistory size={20} className='min-w-max' /> },
            ]
        },
        {
            section: "RESPONSABLES",
            links: [
                { name: "Responsables", link: "/responsables", icon: <LiaUserCogSolid size={20} className='min-w-max' /> },
                { name: "Groupes", link: "/groups", icon: <LiaUsersSolid size={22} className='min-w-max' /> }
            ]
        },
        {
            section: "AGENTS",
            links: [
                { name: "Agents", link: "/agents", icon: <LuUsers2 size={20} className='min-w-max' /> },
                { name: "Historique", link: "/agents-historique", icon: <LiaUserClockSolid size={20} className='min-w-max' /> },
                { name: "Paiement", link: "/paiement", icon: <AiOutlineDollar size={20} className='min-w-max' /> },
                { name: "Pointage", link: "/pointage", icon: <LiaUserEditSolid size={20} className='min-w-max' /> },
            ]
        },
        {
            section: 'PARAMÈTRES',
            links: [
                { name: "Profil", link: "/profil", icon: <AiOutlineUser size={20} className='min-w-max' /> },
                { name: "Se déconnecter", link: "/", icon: <AiOutlineLogout size={20} className='min-w-max' />, onClick: () => logout() }
            ]
        }
    ]

    let isTab = useMediaQuery({ query: "(max-width: 768px)" });
    const { pathname } = useLocation();

    const [isOpen, setIsOpen] = useState(isTab ? false : true);

    const Sidebar_animation = isTab
        ? {
            open: {
                x: 0,
                width: "16rem",
                transition: {
                    damping: 40,
                },
            },
            closed: {
                x: -250,
                width: 0,
                transition: {
                    damping: 40,
                    delay: 0.15,
                },
            }
        }
        : {
            open: {
                width: "16rem",
                transition: {
                    damping: 40,
                },
            },
            closed: {
                width: "4rem",
                transition: {
                    damping: 40,
                },
            }
        };

    useEffect(() => {
        if (isTab) {
            // MOBILE
            setIsOpen(false)
        } else {
            // DESKTOP
            setIsOpen(true)
        }
    }, [isTab]);

    // If the pathname changed, close the sidebar
    useEffect(() => {
        isTab && setIsOpen(false)
    }, [pathname]);

    const logout = () => {
        localStorage.removeItem('id');
        localStorage.removeItem('isAuth');
        localStorage.removeItem('admin_name');
    };

    return (
        <div>
            <div
                onClick={() => setIsOpen(false)}
                className={`md:hidden fixed inset-0 max-h-screen z-[998] bg-black/50 ${isOpen ? 'block' : 'hidden'}`}>
            </div>

            <motion.div
                variants={Sidebar_animation}
                initial={{ x: isTab ? -250 : 0 }}
                animate={isOpen ? 'open' : 'closed'}
                className='bg-white text-[gray] shadow-gray shadow-lg z-[999] w-[14rem] max-w-[14rem] h-full overflow-hidden md:relative fixed min-h-screen'
            >
                {/* LOGO */}
                {
                    isOpen
                        ? (
                            <div className='font-medium pt-5 mx-3 flex justify-center text-center'>
                                <img src={Logo} alt="" className='w-[60px]' />
                            </div>
                        )
                        : (
                            <div className='text-center font-medium py-5 pb-5 mx-3 flex justify-center'>
                                <img src={Logo} alt="" className='w-[80px]' />
                            </div>
                        )
                }

                {/* Menus */}
                <div className={`flex flex-col h-full`}>
                    {/* First */}
                    {/* <ul className={`whitespace-pre px-2.5 text-[0.9rem] py-5 flex flex-col gap-1 font-medium overflow-x-hidden scrollbar-thin scrollbar-track-white scrollbar-thumb-[#ccc] h-[100%] md:h-[100%]`}>
                        {
                            links?.map((link, i) => (
                                <li key={i}>
                                    <NavLink
                                        to={link?.link}
                                        className={`${link?.margin && 'border-t border-light mt-10 rounded-none'} grid p-2.5 rounded-md md:cursor-pointer duration-300 font-medium`}
                                        onClick={link?.onClick}
                                    >
                                        <p className='order-first flex'>{link?.icon} <span className='ml-6'>{link?.name}</span></p>
                                    </NavLink>
                                </li>
                            ))
                        }
                    </ul> */}

                    <div className="">

                        <ul className={`whitespace-pre px-2.5 text-[0.7rem] mt-2 flex flex-col font-medium overflow-x-hidden scrollbar-thin scrollbar-track-white scrollbar-thumb-[#ccc] h-[100%] md:h-[100%]`}>
                            {
                                links?.map((section, sectionIndex) => (
                                    <div key={sectionIndex}>
                                        <p className={`text-[0.66rem] text-black mt-3 mb-1 font-semibold ${isOpen ? '' : 'hidden'}`}>{section.section}</p>
                                        {
                                            section.links.map((link, linkIndex) => (
                                                <li key={linkIndex}>
                                                    <NavLink
                                                        to={link?.link}
                                                        className={`${link?.margin && 'border-t border-light mt-2 rounded-none'} grid p-2.5 rounded-md md:cursor-pointer duration-300 font-medium`}
                                                        onClick={link?.onClick}
                                                    >
                                                        <p className='order-first flex'>{link?.icon} <span className='ml-6'>{link?.name}</span></p>
                                                    </NavLink>
                                                </li>
                                            ))
                                        }
                                    </div>
                                ))
                            }
                        </ul>

                    </div>

                </div>

                {/* CLOSE AND OPEN ARROW ICON  */}
                <motion.div
                    animate={
                        isOpen
                            ? {
                                x: 0,
                                y: 0,
                                rotate: 0
                            }
                            : {
                                x: 0,
                                y: 0,
                                rotate: 180
                            }
                    }
                    transition={{
                        duration: 0,
                    }}
                    onClick={() => setIsOpen(!isOpen)}
                    className='absolute w-fit h-fit z-50 right-2 bottom-2 cursor-pointer md:block hidden p-2 text-black'
                >
                    <IoIosArrowBack size={25} />
                </motion.div>

            </motion.div>

            <div className='m-3 md:hidden' onClick={() => setIsOpen(true)}>
                <MdMenu size={25} />
            </div>
        </div>
    )
}

export default Sidebar;