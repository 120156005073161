import { where, query, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc } from "firebase/firestore";
import { affaireCollection, deletedItemsCollection } from './firebase';

export const getAffaires = async () => {
    try {
        const querySnapshot = await getDocs(query(affaireCollection));
        const affaires = querySnapshot.docs.map((doc) => doc.data());

        // Sort affaires based on the numeric part of the codeAffaire
        affaires.sort((a, b) => {
            return b.codeAffaire.localeCompare(a.codeAffaire);
        });

        //console.log(affaires);

        return affaires;
    } catch (error) {
        return [];
    }
};

export const editAffaire = async (affaireId, codeAffaire, nameAffaire) => {
    try {
        const adminName = localStorage.getItem('admin_name');

        let newData = {
            codeAffaire: codeAffaire,
            nameAffaire: nameAffaire,
            editedBy: `Edited by ${adminName} at this date: ${new Date().toISOString()}`
        };

        // function to get the data 
        const checkAffaireExists = async (field, value) => {
            const affairesQuery = query(
                affaireCollection,
                where(field, '==', value)
            );

            const querySnapshot = await getDocs(affairesQuery);
            return querySnapshot.docs.map((doc) => doc.data());
        };

        const existingAffaireByCode = await checkAffaireExists('codeAffaire', codeAffaire);
        const existingAffaireByName = await checkAffaireExists('nameAffaire', nameAffaire);

        const affaireData = (await getDoc(doc(affaireCollection, affaireId))).data();

        if (affaireData.codeAffaire !== codeAffaire && existingAffaireByCode.length > 0) {
            return 0;
        } else if (affaireData.nameAffaire !== nameAffaire && existingAffaireByName.length > 0) {
            return 0;
        } else {
            await setDoc(doc(affaireCollection, affaireId), newData, { merge: true });
        }
    } catch (error) {
        return 0;
    }
};

export const addAffaire = async (code, name, date) => {
    try {
        const adminName = localStorage.getItem('admin_name');

        const data = {
            codeAffaire: code,
            nameAffaire: name,
            createdBy: adminName,
            createDate: new Date(date)
        }

        const checkAffaireExists = async (field, value) => {
            const affairesQuery = query(
                affaireCollection,
                where(field, '==', value)
            );

            const querySnapshot = await getDocs(affairesQuery);
            return querySnapshot.docs.map((doc) => doc.data());
        }

        const existingAffaireByCode = await checkAffaireExists('codeAffaire', code);
        const existingAffaireByName = await checkAffaireExists('nameAffaire', name);

        if (existingAffaireByCode.length > 0) {
            return '0';
        } else if (existingAffaireByName.length > 0) {
            return '1';
        } else {
            const affaireRef = await addDoc(affaireCollection, data);
            await setDoc(doc(affaireCollection, affaireRef.id), { affaire_id: affaireRef.id }, { merge: true });
            return '2';
        }
    } catch (error) {
        return '3';
    }
};

export const deleteAffaire = async (id) => {
    try {
        const adminName = localStorage.getItem('admin_name');

        const deletedAffaireRef = doc(affaireCollection, id);
        const deletedAffaireSnapshot = await getDoc(deletedAffaireRef);
        const deletedAffaireData = deletedAffaireSnapshot.data();

        let deletedItemData = {
            nameAffaire: deletedAffaireData.nameAffaire,
            codeAffaire: deletedAffaireData.codeAffaire,
            deletedBy: adminName,
            deletedDate: new Date().toISOString()
        }

        // add the deleted obj to the DeletedItems 
        await addDoc(deletedItemsCollection, deletedItemData);

        // delete the doc 
        await deleteDoc(deletedAffaireRef);
    } catch (error) {
        //console.log(error)
    }
};

export const getAllAffairesForSelect = async () => {
    try {

        const querySnapshot = await getDocs(affaireCollection);
        const affaires = querySnapshot.docs.map((doc) => {
            const { nameAffaire, codeAffaire } = doc.data();
            return { label: `${codeAffaire} - ${nameAffaire}`, value: codeAffaire };
        });

        // Sort affaires based on the numeric part of the codeAffaire
        // affaires.sort((a, b) => {
        //     return b.value.localeCompare(a.value);
        // });


        return affaires;
    } catch (error) {
        return [];
    }
};