import React, { useEffect, useState } from "react";
// Components :
import Sidebar from "../components/sidebar/Sidebar";
import { AddGroupModal } from "../components/groupsComponenets/AddGroupModal";
import { EditGroupModal } from "../components/groupsComponenets/EditGroupModal";
import SearchBar from "../components/searchBar/SearchBar";
import { getAllGroups } from "../config/groups";
import { Pagination } from "../components/paginationComponent/Pagination";
// Icons :
import { BsDatabaseSlash } from "react-icons/bs";
// Toast :
import { Toaster } from "sonner";
import "react-toastify/dist/ReactToastify.css";
// Packages :
import { Skeleton } from "@mui/material";

export const Groups = () => {
    const [groups, setGroups] = useState("");
    const [filteredData, setFilteredData] = useState(groups);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(false);

    // ? =============================|| Get All Groups ||=============================
    const handleGetGroups = async () => {
        setLoading(true);
        const result = await getAllGroups();
        setLoading(false);
        setGroups(result);
        setFilteredData(result);
    };

    useEffect(() => {
        handleGetGroups();
    }, []);
    // ? ==============================================================================


    // ? ===============================|| Pagination ||===============================
    const itemsPerPage = 10; // Set the number of items per page
    const [currentPage, setCurrentPage] = useState(1);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // Assuming respoAffaires is your data array
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    // ? ==============================================================================

    // ? =========================|| Handle Search Function ||=========================
    const handleSearch = (searchTerm) => {
        setSearchQuery(searchTerm);

        // If the search query is empty, display all agents
        if (searchTerm.trim() === "") {
            setFilteredData(groups);
        } else {
            // Otherwise, filter the data based on the search term
            const filteredResults = groups.filter((group) => {
                const searchTermLower = searchTerm.toLowerCase();
                const groupID = group.group_id;

                // Check for an exact match in the group ID
                return (
                    group?.group_name?.toLowerCase().includes(searchTermLower) ||
                    groupID === searchTermLower
                );
            });

            setCurrentPage(1);
            setFilteredData(filteredResults);
        }
    };
    // ? ==============================================================================

    return (
        <div className="flex gap-2 md:gap-3 lg:gap-5 bg-[#F4F9FF]">
            <Sidebar />
            <main className="flex-1 mx-auto py-4 pr-4 h-screen overflow-y-auto">
                {/* <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover={false}
                    theme="colored"
                /> */}

                <Toaster expand={true} richColors />

                <div className="flex justify-between items-center mb-10">
                    <p className="text-3xl lg:text-4xl font-bold">Grpoupes</p>
                    <AddGroupModal onAdd={() => handleGetGroups()} />
                </div>

                {/* Search Bar */}
                <SearchBar
                    onSearch={handleSearch}
                    placeholder="Rechercher par nom ou groupe ID"
                />

                <div>
                    <p className="text-gray text-sm mb-2">
                        Ici vous pouvez trouver les agents et vous pouvez ajouter, modifier
                        et supprimer un agent.
                    </p>

                    <table className="w-full overflow-hidden rounded-t-lg">
                        <thead className="bg-primary text-white py-2">
                            <tr>
                                <td className="py-3 text-center text-sm lg:text-base border border-white font-bold">
                                    Groupe ID
                                </td>
                                <td className="py-3 text-center text-sm lg:text-base border border-white font-bold">
                                    Group Short Name
                                </td>
                                <td className="py-3 text-center text-sm lg:text-base border border-white font-bold">
                                    Group Name
                                </td>
                                <td className="py-3 text-center text-sm lg:text-base border border-white font-bold">
                                    Action
                                </td>
                            </tr>
                        </thead>

                        <tbody className="bg-white">
                            {loading ? (
                                <tr>
                                    <td
                                        colSpan={4}
                                    >
                                        <Skeleton animation="wave" width="98%" height={50} className='mx-auto' />
                                        <Skeleton animation="wave" width="98%" height={50} className='mx-auto' />
                                        <Skeleton animation="wave" width="98%" height={50} className='mx-auto' />
                                        <Skeleton animation="wave" width="98%" height={50} className='mx-auto' />
                                        <Skeleton animation="wave" width="98%" height={50} className='mx-auto' />
                                        <Skeleton animation="wave" width="98%" height={50} className='mx-auto' />
                                    </td>
                                </tr>
                            ) :
                                currentItems.length > 0 ? (
                                    currentItems.map((group, i) => (
                                        <tr key={i}>
                                            <td
                                                className={`border border-gray text-center py-2 px-3 text-sm lg:text-base font-bold`}
                                            >
                                                {group?.group_id ? group?.group_id : "-"}
                                            </td>
                                            <td
                                                className={`border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                                            >
                                                {group?.shortName ? group?.shortName : "-"}
                                            </td>
                                            <td
                                                className={`border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                                            >
                                                {group?.group_name ? group?.group_name : "-"}
                                            </td>
                                            <td
                                                className={`border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                                            >
                                                <div className="flex items-center justify-center">
                                                    <EditGroupModal
                                                        groupId={group?.group_id ? group?.group_id : "-"}
                                                        ShortName={group?.shortName ? group?.shortName : "-"}
                                                        groupName={group?.group_name ? group?.group_name : "-"}
                                                        id={group?.GroupId ? group?.GroupId : "-"}
                                                        page={currentPage}
                                                        searchQuery={searchQuery}
                                                        onEdit={() => handleGetGroups()}
                                                    />
                                                    {/* <DeleteAgentModal
                                                    agentId={agent?.user_id ? agent?.user_id : "-"}
                                                    name={agent?.fullName ? agent?.fullName : "-"}
                                                    onDelete={() => handleGetGroups()}
                                                />*/}
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="7" className="text-center py-4 text-gray">
                                            <div className="flex flex-col justify-center items-center mt-4">
                                                <BsDatabaseSlash size={30} className="mb-2" />
                                                <p>Pas de données disponibles.</p>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                        </tbody>
                    </table>
                </div>
                
                <Pagination
                    itemsPerPage={itemsPerPage}
                    data={filteredData}
                    onPageChange={handlePageChange}
                    currentPage={currentPage}
                />
            </main>
        </div>
    );
};
