import React, { useEffect, useRef, useState } from "react";
// Components :
import Sidebar from "../components/sidebar/Sidebar";
// import { PaiementDetailsModal } from "../components/paiementComponents/PaiementDetailsModal";
// ? Packages :
// React-select :
import Select from "react-select";
// Icons :
import { FaRegFilePdf } from "react-icons/fa6";
import { AiOutlineSearch } from "react-icons/ai";
import { BsDatabaseSlash } from "react-icons/bs";
import { PiMicrosoftExcelLogoThin } from "react-icons/pi";
// Toast :
import { Toaster, toast } from 'sonner';
// XLSX :
import * as XLSX from "xlsx";
// Screen to pdf
import { useReactToPrint } from "react-to-print";
// ? Firebase & Functions :
import { getDocs, query, where } from "firebase/firestore";
import { pointageCollection, userCollection } from "../config/firebase";
import { getGroups } from "../config/groups";
import { Skeleton } from "@mui/material";

export const Paiement = () => {
    const [data, setData] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState("");
    const [groups, setGroups] = useState("");
    const [selectedGroup, setSelectedGroup] = useState("");
    const [loading, setLoading] = useState(false);
    // Export Data :
    const [agent, setAgent] = useState("");
    const [sj, setSJ] = useState("");
    const [nJours, setNJours] = useState("");
    const [nDimanche, setNDimanche] = useState("");
    const [nJoureFerie, setNJoureFerie] = useState("");
    const [nDeplecement, setNDeplacement] = useState("");
    const [nTransport, setNTranport] = useState("");
    const [nConje, setNConje] = useState("");
    const [nMalade, setNMalade] = useState("");
    const [nAbsent, setNAbsent] = useState("");
    const [hSupp, setHSupp] = useState("");
    const [tHours, setTHours] = useState("");
    const [salaire, setSalaire] = useState("");
    const [title, setTitle] = useState("");

    // ? ===============================|| Inpust Handlers ||===============================
    const handleSelectedGroup = (e) => {
        setSelectedGroup(e.value);
    };
    const handleSelectedMonth = (event) => {
        setSelectedMonth(event.target.value);
    };
    // ? ===================================================================================

    useEffect(() => {
        const handleGetGroups = async () => {
            const result = await getGroups();
            let all = { label: "TOUS LES GROUPES", value: "-1" };
            result.unshift(all);
            console.log(result)
            setGroups(result);
        };

        handleGetGroups();
    }, []);

    useEffect(() => {
        const currentDate = new Date();
        const thisMonth = currentDate.toISOString().slice(0, 7);

        setSelectedMonth(thisMonth);
    }, []);

    function getDateForPointage(selectedMonth) {
        // Parse the selected month string, e.g., "2022-02"
        const [year, month] = selectedMonth.split("-");

        // Set the date to the first day of the selected month
        const startDate = new Date(parseInt(year), parseInt(month) - 1, 1);

        // Set the date to the last day of the selected month
        const endDate = new Date(parseInt(year), parseInt(month), 0);

        // Adjust to local time zone (GMT+1)
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);

        let date = {
            start: startDate,
            end: endDate,
        };

        return date;
    }

    const fetchPointage = async (month, agents) => {
        const date = getDateForPointage(month);

        try {
            let pointage = [];

            // Use Promise.all to wait for all async operations to complete
            await Promise.all(
                agents.map(async (ag) => {
                    const pointageQuery = query(
                        pointageCollection,
                        where("user_id", "==", ag.user_id),
                        where("verified", "==", true),
                        where("date", ">=", new Date(date.start)),
                        where("date", "<=", new Date(date.end))
                    );

                    const querySnapshot = await getDocs(pointageQuery);
                    const result = querySnapshot.docs.map((doc) => doc.data());

                    result.map((res) => pointage.push(res));
                })
            );

            return pointage;
        } catch (error) {
            console.error("Error occurred:", error);
            throw error;
        }
    };

    const fetchAgents = async (group) => {
        try {
            let agentsQuery;

            if (group === "-1") {
                agentsQuery = query(userCollection, where("group_id", "!=", "100"));
            } else {
                agentsQuery = query(userCollection, where("group_id", "==", group));
            }

            const querySnapshot = await getDocs(agentsQuery);
            const agents = querySnapshot.docs.map((doc) => doc.data());

            return agents;
        } catch (error) { }
    };

    function isSunday(timestamp) {
        const poiTimestamp =
            timestamp.seconds * 1000 + Math.round(timestamp.nanoseconds / 1e6);

        const date = new Date(poiTimestamp);
        return date.getDay() === 0; // Sunday is represented by 0 in JavaScript's Date object
    }

    const processData = (agents, pointage) => {
        let data = [];

        agents.forEach((ag) => {
            let name = ag.role === "Respo" ? `${ag.fullName} (Respo)` : ag.fullName;
            let pricePerDay = ag.pricePerDay;
            let pricePerHour = pricePerDay / 8;
            let numJour = 0,
                numConge = 0,
                numAbsent = 0,
                numMalade = 0,
                numSunday = 0,
                numTrasport = 0,
                numDeplacment = 0,
                numHolidays = 0,
                NormalHours = 0,
                ExtraHours = 0,
                totHours = 0,
                totTrasport = 0,
                totDeplacment = 0,
                totPayment = 0;

            pointage.forEach((poi) => {
                if (ag.user_id === poi.user_id) {
                    if (poi.normalWork.length > 0 && poi.holiday === true) {
                        numHolidays++;
                        NormalHours += Number(poi.totalNormalwork);
                        ExtraHours += Number(poi.totalExtrawork);
                        totHours += Number(poi.totalHours);
                        totTrasport += Number(poi.transportPrice);
                        totDeplacment += Number(poi.deplacementPrice);
                        if (poi.deplacement) numDeplacment++;
                        if (poi.transport) numTrasport++;
                    } else if (poi.normalWork.length > 0 && isSunday(poi.date)) {
                        numSunday++;
                        NormalHours += Number(poi.totalNormalwork);
                        ExtraHours += Number(poi.totalExtrawork);
                        totHours += Number(poi.totalHours);
                        totTrasport += Number(poi.transportPrice);
                        totDeplacment += Number(poi.deplacementPrice);
                        if (poi.deplacement) numDeplacment++;
                        if (poi.transport) numTrasport++;
                    } else if (poi.normalWork.length > 0) {
                        numJour++;
                        NormalHours += Number(poi.totalNormalwork);
                        ExtraHours += Number(poi.totalExtrawork);
                        totHours += Number(poi.totalHours);
                        totTrasport += Number(poi.transportPrice);
                        totDeplacment += Number(poi.deplacementPrice);
                        if (poi.deplacement) numDeplacment++;
                        if (poi.transport) numTrasport++;
                    } else if (poi.normalWork.length === 0 && poi.conge === true) {
                        numConge++;
                    } else if (poi.normalWork.length === 0 && poi.absent === true) {
                        numAbsent++;
                    } else if (poi.normalWork.length === 0 && poi.malade === true) {
                        numMalade++;
                    }
                }
            });

            totPayment =
                numJour * pricePerDay +
                numSunday * 1.5 * pricePerDay +
                numHolidays * 2 * pricePerDay +
                totDeplacment +
                totTrasport +
                ExtraHours * pricePerHour +
                numConge * pricePerDay;

            let obj = {
                name,
                pricePerDay: Number(pricePerDay),

                numJour: Number(numJour),
                numConge: Number(numConge),
                numAbsent: Number(numAbsent),
                numMalade: Number(numMalade),
                numSunday: Number(numSunday),
                numHolidays: Number(numHolidays),
                numTrasport: Number(numTrasport),
                numDeplacment: Number(numDeplacment),

                NormalHours: Number(NormalHours),
                ExtraHours: Number(ExtraHours),

                totHours: Number(totHours),
                totTrasport: Number(totTrasport),
                totDeplacment: Number(totDeplacment),
                totPayment: Number(totPayment).toFixed(2),
            };

            data.push(obj);
        });

        // Sort the data array based on totPayment in descending order
        // data.sort((a, b) => b.totPayment - a.totPayment);
        data.sort((a, b) => a.name.localeCompare(b.name));

        dataFormat(data);
        return data;
    };

    const getdata = async (month, group) => {
        try {
            if (!group) {
                // Show toast if group is not selected
                toast.info("Veuillez sélectionner un groupe !");
                return;
            }

            setLoading(true);
            const agents = await fetchAgents(group);
            const pointage = await fetchPointage(month, agents);
            const processedData = processData(agents, pointage);

            setData(processedData);
            setTitle(`Pointage de ce mois : ${month}`)
            //console.log(processData);

            // dataFormat(processedData);
            setLoading(false);
        } catch (error) {
            console.error("Error occurred:", error);
        }
    };

    // ? ===================================================================================

    // ? ===================================|| Export to CSV ||====================================
    const notifyInfo = (message) =>
        toast.info(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });

    const dataFormat = (d) => {
        let agent = [];
        let sj = [];
        let nJours = [];
        let nDimanche = [];
        let nJoureFerie = [];
        let nDeplecement = [];
        let nTransport = [];
        let nConje = [];
        let nMalade = [];
        let nAbsent = [];
        let hSupp = [];
        let tHours = [];
        let salaire = [];

        for (let n = 0; n < d.length; n++) {
            agent.push(d[n].name);
            sj.push(d[n].pricePerDay);
            nJours.push(d[n].numJour);
            nDimanche.push(d[n].numSunday);
            nJoureFerie.push(d[n].numHolidays);
            nDeplecement.push(d[n].numDeplacment);
            nTransport.push(d[n].numTrasport);
            nConje.push(d[n].numConge);
            nMalade.push(d[n].numMalade);
            nAbsent.push(d[n].numAbsent);
            hSupp.push(d[n].ExtraHours);
            tHours.push(d[n].totHours);
            salaire.push(d[n].totPayment);
        }

        setAgent(agent);
        setSJ(sj);
        setNJours(nJours);
        setNDimanche(nDimanche);
        setNJoureFerie(nJoureFerie);
        setNDeplacement(nDeplecement);
        setNTranport(nTransport);
        setNConje(nConje);
        setNMalade(nMalade);
        setNAbsent(nAbsent);
        setHSupp(hSupp);
        setTHours(tHours);
        setSalaire(salaire);
    };

    const dataExport = () => {
        let result = `Nom d'agent, S J, N. Jours Heures, N. Dimanche, N. J férié, N. Deplacment, N. Trasport, N. Conjé, N. Malade, NAbsent, H.Supp, T.Heures, Total Salaire \n`;
        for (let i = 0; i < data.length; i++) {
            result += `${agent[i]}, ${sj[i]}, ${nJours[i]}, ${nDimanche[i]}, ${nJoureFerie[i]}, ${nDeplecement[i]}, ${nTransport[i]}, ${nConje[i]}, ${nMalade[i]},${nAbsent[i]}, ${hSupp[i]},${tHours[i]}, ${salaire[i]},\n`;
        }
        return result;
    };

    // Export to xlsx :
    async function exportToCsv() {
        if (data.length > 0) {
            const dataArray = dataExport()
                .split("\n")
                .map((row) => row.split(","));
            const worksheet = XLSX.utils.aoa_to_sheet(dataArray);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

            const filename = `PTrack Paiement ${selectedMonth}.xlsx`;

            XLSX.writeFile(workbook, filename);
        } else {
            // notifyInfo("Pas de données disponibles.");
            toast.warning('Pas de données disponibles !')
        }
    }
    // ? ===========================================================================================

    const contentToPrint = useRef(null);
    const handlePrint = useReactToPrint({
        documentTitle: "Print This Document",
    });

    return (
        <div className="flex gap-2 md:gap-3 lg:gap-5 bg-[#F4F9FF]">
            <Sidebar />
            <main className="flex-1 mx-auto py-4 pr-4 h-screen overflow-y-auto">
                {/* Toast */}
                {/* <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    limit={1}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover={false}
                    theme="light"
                /> */}

                <Toaster expand={true} richColors />

                <div className="flex justify-between items-center mb-10">
                    <p className="text-3xl lg:text-4xl font-bold">Paiement</p>
                </div>

                <div className="flex flex-wrap justify-between items-end mb-10">
                    <div className="w-full lg:w-2/3 lg:flex items-center">
                        {/* Groups */}
                        <div className="w-full lg:2/3 mr-2">
                            <p className="font-medium">Groupe</p>
                            {/* <Select
                                size="large"
                                className="w-full rounded-md P-4"
                                showSearch
                                placeholder="Select a person"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                onChange={handleSelectedGroup}
                                value={selectedGroup}
                                options={groups}
                            /> */}
                            <Select
                                options={groups}
                                isSearchable={true}
                                onChange={handleSelectedGroup}
                            />
                        </div>

                        {/* Start Date */}
                        <div className="w-full lg:w-1/3 mr-2">
                            <p className="font-medium">Mois</p>
                            <input
                                type="month"
                                className="w-full border border-gray rounded-md py-2 px-4"
                                value={selectedMonth}
                                onChange={handleSelectedMonth}
                            />
                        </div>
                    </div>

                    {/* Buttons */}
                    <div className="w-full lg:w-1/3 flex justify-end items-center mt-4 md:mt-2 lg:mt-0">
                        <button
                            onClick={() => getdata(selectedMonth, selectedGroup)}
                            className="flex justify-center items-center bg-primary rounded-md px-4 py-2 text-white mr-2"
                        >
                            <AiOutlineSearch size={22} className="mr-4" /> Recherche
                        </button>

                        <button
                            onClick={() => exportToCsv()}
                            className="flex justify-center items-center bg-primary rounded-md px-4 py-2 text-white"
                        >
                            <PiMicrosoftExcelLogoThin size={22} className="mr-4" /> Exporter
                        </button>

                        {/* <ScreenshotCapture /> */}
                        {/* <Exporter /> */}
                    </div>
                </div>

                {/* Export as pdf Button */}
                <div className="w-full flex justify-end mb-2">
                    <button
                        onClick={() => {
                            handlePrint(null, () => contentToPrint.current);
                        }}
                        className="flex justify-center items-center rounded-md px-4 py-2 text-primary border border-primary hover:bg-primary hover:text-white transition-all"
                    >
                        <FaRegFilePdf size={22} className="mr-4" /> Imprimer en pdf
                    </button>
                </div>

                {/* Agents Table */}
                <div className="m-4" ref={contentToPrint}>
                    <div className="overflow-x-scroll mt-2">
                        {/* Table */}
                        <table
                            className="w-full overflow-hidden rounded-t-lg"
                            id="element-to-print"
                        >
                            <thead className="bg-primary text-white py-2">
                                <tr>
                                    <td colSpan={13} className="py-3 text-center text-sm lg:text-base border border-white">{title}</td>
                                </tr>
                                <tr>
                                    <td className="py-3 text-center text-sm lg:text-base border border-white">
                                        Nom d'agent
                                    </td>
                                    <td className="py-3 text-center text-sm lg:text-base border border-white">
                                        S J
                                    </td>
                                    <td className="py-3 text-center text-sm lg:text-base border border-white">
                                        N. Jours
                                    </td>
                                    <td className="py-3 text-center text-sm lg:text-base border border-white">
                                        N. Dimanche
                                    </td>
                                    <td className="py-3 text-center text-sm lg:text-base border border-white">
                                        N. J férié
                                    </td>
                                    <td className="py-3 text-center text-sm lg:text-base border border-white">
                                        N. Deplacment
                                    </td>
                                    <td className="py-3 text-center text-sm lg:text-base border border-white">
                                        N. Trasport
                                    </td>
                                    <td className="py-3 text-center text-sm lg:text-base border border-white">
                                        N. Conjé
                                    </td>
                                    <td className="py-3 text-center text-sm lg:text-base border border-white">
                                        N. Malade
                                    </td>
                                    <td className="py-3 text-center text-sm lg:text-base border border-white">
                                        N. Absent
                                    </td>
                                    <td className="py-3 text-center text-sm lg:text-base border border-white">
                                        H. Supp
                                    </td>
                                    <td className="py-3 text-center text-sm lg:text-base border border-white">
                                        T. Heures
                                    </td>
                                    <td className="py-3 text-center text-sm lg:text-base border border-white">
                                        Total Salaire
                                    </td>
                                </tr>
                            </thead>

                            <tbody className="bg-white">
                                {loading ? (
                                    <tr className="borber border-black">
                                        <td colSpan={14}>
                                            <Skeleton
                                                animation="wave"
                                                width="98%"
                                                height={50}
                                                className="mx-auto"
                                            />
                                            <Skeleton
                                                animation="wave"
                                                width="98%"
                                                height={50}
                                                className="mx-auto"
                                            />
                                            <Skeleton
                                                animation="wave"
                                                width="98%"
                                                height={50}
                                                className="mx-auto"
                                            />
                                            <Skeleton
                                                animation="wave"
                                                width="98%"
                                                height={50}
                                                className="mx-auto"
                                            />
                                            <Skeleton
                                                animation="wave"
                                                width="98%"
                                                height={50}
                                                className="mx-auto"
                                            />
                                            <Skeleton
                                                animation="wave"
                                                width="98%"
                                                height={50}
                                                className="mx-auto"
                                            />
                                        </td>
                                    </tr>
                                ) : data.length > 0 ? (
                                    data.map((poi, i) => (
                                        <tr>
                                            <td className="py-3 text-center text-sm lg:text-base border border-gray">
                                                {poi.name}
                                            </td>
                                            <td className="py-3 text-center text-sm lg:text-base border border-gray">
                                                {poi.pricePerDay}
                                            </td>
                                            <td className="py-3 text-center text-sm lg:text-base border border-gray">
                                                {poi.numJour}
                                            </td>
                                            <td className="py-3 text-center text-sm lg:text-base border border-gray">
                                                {poi.numSunday}
                                            </td>
                                            <td className="py-3 text-center text-sm lg:text-base border border-gray">
                                                {poi.numHolidays}
                                            </td>
                                            <td className="py-3 text-center text-sm lg:text-base border border-gray">
                                                {poi.numDeplacment}
                                            </td>
                                            <td className="py-3 text-center text-sm lg:text-base border border-gray">
                                                {poi.numTrasport}
                                            </td>
                                            <td className="py-3 text-center text-sm lg:text-base border border-gray">
                                                {poi.numConge}
                                            </td>
                                            <td className="py-3 text-center text-sm lg:text-base border border-gray">
                                                {poi.numMalade}
                                            </td>
                                            <td className="py-3 text-center text-sm lg:text-base border border-gray">
                                                {poi.numAbsent}
                                            </td>
                                            <td className="py-3 text-center text-sm lg:text-base border border-gray">
                                                {poi.ExtraHours}
                                            </td>
                                            <td className="py-3 text-center text-sm lg:text-base border border-gray">
                                                {poi.totHours}
                                            </td>
                                            <td className="py-3 text-center text-sm lg:text-base border border-gray">
                                                {poi.totPayment}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="14" className="text-center py-4 text-gray">
                                            <div className="flex flex-col justify-center items-center mt-4">
                                                <BsDatabaseSlash size={30} className="mb-2" />
                                                <p>Pas de données disponibles.</p>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </main>
        </div>
    );
};
