import React, { useState } from 'react';

const SearchBar = ({ onSearch, placeholder }) => {
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearch = (event) => {
        const searchTerm = event.target.value;
        setSearchQuery(searchTerm);
        onSearch(searchTerm);
    };

    return (
        <div className='mb-4'>
            <input
                type='search'
                className='w-full rounded-md border border-gray p-2 outline-none'
                placeholder={placeholder}
                value={searchQuery}
                onChange={handleSearch}
            />
        </div>
    );
};

export default SearchBar;
