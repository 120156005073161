import {
  where,
  query,
  getDocs,
  getDoc,
  doc,
  setDoc,
  addDoc,
  deleteDoc,
} from "firebase/firestore";
import { auth, deletedItemsCollection, userCollection } from "./firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";

export const getAgents = async () => {
  try {
    const agentsQuery = query(userCollection, where("role", "==", "Agent"));

    const querySnapshot = await getDocs(agentsQuery);
    const agents = querySnapshot.docs.map((doc) => doc.data());

    // Sort the agents array by name
    agents.sort((a, b) => a.fullName.localeCompare(b.fullName));

    return agents;
  } catch (error) {
    return [];
  }
};

export const addAgent = async (
  fullName,
  group_id,
  email,
  price,
  cin,
  cnss,
  phone
) => {
  try {
    // Step 1: Create user in Firebase Authentication
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      "pprime@2024"
    );
    const user = userCredential.user;
    const userId = user.uid;

    let data = {
      fullName: fullName,
      email: email,
      role: "Agent",
      group_id: group_id,
      originalGroup_id: group_id,
      password: "pprime@2024",
      passwordChanged: false,
      user_id: userId,
      pricePerDay: price,
      cin: cin,
      cnss: cnss,
      phone: phone,
      createdBy: localStorage.getItem("admin_name"),
      createdDate: new Date(),
    };

    // Step 2: Create user document in Firestore with UID as the document ID
    const userDocRef = doc(userCollection, userId);
    await setDoc(userDocRef, data);

    return "Created";
  } catch (error) {
    return "Exist";
  }
};

export const editAgent = async (
  id,
  name,
  cin,
  cnss,
  phone,
  pricePerDay,
  groupId
) => {
  try {
    let newData = {
      fullName: name,
      group_id: groupId,
      cin: cin,
      cnss: cnss,
      phone: phone,
      pricePerDay: pricePerDay,
    };

    await setDoc(doc(userCollection, id), newData, { merge: true });

    return "Changed";
  } catch (error) {
    return "notChanged";
  }
};

export const deleteAgent = async (id) => {
  try {
    const adminName = localStorage.getItem("respo_name");

    // Fetch user data from Firestore
    const deletedAgent = doc(userCollection, id);
    const deletedAgentSnapshot = await getDoc(deletedAgent);
    const deletedAgentData = deletedAgentSnapshot.data();

    if (!deletedAgentData) {
      console.error(`User with ID ${id} not found`);
      return "notDeleted";
    }

    // Create a log for deleted item
    const deletedItemData = {
      group_id: deletedAgentData.group_id,
      respoName: deletedAgentData.fullName,
      email: deletedAgentData.email,
      deletedBy: `Responsable '${deletedAgentData.fullName
        }' deleted by '${adminName}', ${new Date().toISOString()} `,
    };

    // Add the deleted object to the DeletedItems collection
    await addDoc(deletedItemsCollection, deletedItemData);

    // // Delete the user from Firebase Authentication
    // const auth = getAuth();
    // await deleteUser(auth, id);

    // Delete the corresponding document from Firestore
    await deleteDoc(deletedAgent);

    return "deleted";
  } catch (error) {
    return "notDeleted";
  }
};

export const getAgnetsForSelect = async () => {
  try {
    const agentsQuery = query(userCollection, where("role", "!=", "Admin"));

    const querySnapshot = await getDocs(agentsQuery);
    const agents = querySnapshot.docs.map((doc) => {
      const { fullName, user_id, role } = doc.data();
      return {
        label: role === "Respo" ? `${fullName} (Respo)` : fullName,
        value: user_id,
      };
    });

    return agents;
  } catch (error) {
    return [];
  }
};

export const getRespoAgentsNamdAndId = async () => {
  try {
    const agentsQuery = query(
      userCollection,
      where("role", "!=", "Admin")
    );

    const querySnapshot = await getDocs(agentsQuery);
    const agents = querySnapshot.docs.map((doc) => {
      const { fullName, user_id } = doc.data();
      return { label: fullName, value: user_id };
    });

    return agents;
  } catch (error) {
    return [];
  }
};