import React, { useEffect, useState } from 'react';
// ? Icons :
import { PiMicrosoftExcelLogoThin } from "react-icons/pi";
import { AiOutlineSearch } from 'react-icons/ai';
import { BsDatabaseSlash } from "react-icons/bs";
import { IoIosCheckmark } from 'react-icons/io';
// ? Packages : 
// import { Select } from 'antd';
import { Skeleton } from '@mui/material';
// React-select :
import Select from 'react-select';
// Toast :
import { Toaster, toast } from 'sonner'
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// XLSX :
import * as XLSX from 'xlsx';
// ? Functions & Firebase :
import { getAgnetsForSelect } from '../config/agentsConfig';
import { affaireCollection, pointageCollection, userCollection } from '../config/firebase';
import { getDocs, query, where } from 'firebase/firestore';
// ? Componenets :
// import { EditModal } from '../components/agentsHistoriqueComponents/EditModal';
import Sidebar from '../components/sidebar/Sidebar';
import { Pagination } from '../components/paginationComponent/Pagination';
import { DeleteHistoriqueModal } from '../components/agentsHistoriqueComponents/DeleteHistoriqueModal';
import { EditHistoriqueModal } from '../components/agentsHistoriqueComponents/EditHistoriqueModal';

export const AgentsHistorique = () => {
    const [agents, setAgents] = useState([]);
    const [historique, setHistorique] = useState([]);
    const [loading, setLoading] = useState(false);
    // Inputs :
    const [selectedAgent, setSelectedAgent] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    // CSV Data : 
    const [time, setTime] = useState([]);
    const [affaires, setAffaires] = useState([]);
    const [extraHr, setExtraHr] = useState([]);
    const [normalHr, setNormaHr] = useState([]);
    const [messages, setMessages] = useState([]);
    const [deplacement, setDeplacement] = useState([]);
    const [transport, setTranssport] = useState([]);
    const [holiday, setHoliday] = useState([]);

    // ? ====================================|| Search Input ||====================================
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    // ? ==========================================================================================

    // ? ===============================|| Handle Selected Inputs ||===============================
    const handleSelectedAgent = (e) => {
        setSelectedAgent(e.value);
    };
    const handleStartDate = (e) => {
        setStartDate(e.target.value);
    };
    const handleEndDate = (e) => {
        setEndDate(e.target.value);
    };
    // ? ==========================================================================================

    // ? ======================================|| Tostify ||=======================================
    // const notifyInfo = (message) => toast.info(message, {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: false,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "colored",
    // });
    // const notifyError = (message) => toast.error(message, {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: false,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "colored",
    // });
    // ? ==========================================================================================

    // ? ================================|| Historique Function ||=================================
    const historiqueFunction = async () => {
        const data = [];

        if (selectedAgent !== '' && startDate !== '' && endDate !== '') {
            if (new Date(endDate) < new Date(startDate)) {
                // notifyInfo('La date de fin doit être postérieure à la date de début.');
                toast.info('La date de fin doit être postérieure à la date de début.')
                return; // Exit the function if the condition is not met
            }

            try {
                setLoading(true);
                let affairesQuery;
                affairesQuery = query(
                    affaireCollection,
                );

                const affairesSnapshot = await getDocs(affairesQuery);
                const affaires = affairesSnapshot.docs.map((doc) => doc.data());

                const pointageQuery = query(
                    pointageCollection,
                    where('user_id', '==', selectedAgent),
                    where('date', '>=', new Date(startDate)),
                    where('date', '<=', new Date(endDate)),
                    where("verified", "==", true),
                );
                const pointageSnapshot = await getDocs(pointageQuery);
                const pointage = pointageSnapshot.docs.map((doc) => doc.data());

                pointage.forEach((poi) => {
                    // Finding Affaires Name
                    const projectsNormalWorkName = poi.normalWork.map((work) => {
                        const affaire = affaires.find((aff) => aff.codeAffaire === work.codeAffaire);
                        return {
                            codeAffaire: affaire ? affaire.codeAffaire : "Code affaire introuvable",
                            affaireName: affaire ? affaire.nameAffaire : "Nom affaire introuvable",
                            hours: work.hours,
                        };
                    });

                    const projectsExtraWorkName = poi.extraWork.map((work) => {
                        const affaire = affaires.find((aff) => aff.codeAffaire === work.codeAffaire);
                        return {
                            codeAffaire: affaire ? affaire.codeAffaire : "Code affaire introuvable",
                            affaireName: affaire ? affaire.nameAffaire : "Nom affaire introuvable",
                            hours: work.hours,
                        };
                    });

                    // Affaires Code :
                    const projectsNormalWork = poi.normalWork;
                    const projectsExtraWork = poi.extraWork;

                    let pointageSummaryMessage = '';
                    projectsNormalWorkName.forEach((project) => {
                        pointageSummaryMessage += `${project.codeAffaire} - ${project.affaireName} / `;
                    });
                    projectsExtraWorkName.forEach((project) => {
                        pointageSummaryMessage += `+${project.codeAffaire} - ${project.affaireName} / `;
                    });

                    // Remove the slash from the last sentence :
                    const updatedPointageSummaryMessage = pointageSummaryMessage.slice(0, -2);

                    let his = {
                        work: {
                            normalWork: projectsNormalWork,
                            extraWork: projectsExtraWork
                        },
                        projectsNormalWorkName,
                        projectsExtraWorkName,
                        totalNormalwork: poi.totalNormalwork,
                        totalExtrawork: poi.totalExtrawork,
                        date: formatTimestamp(poi.date),
                        affaires: updatedPointageSummaryMessage,
                        transport: poi.transport,
                        transportPrice: poi.transportPrice,
                        deplacement: poi.deplacement,
                        deplacementPrice: poi.deplacementPrice,
                        message: poi.message,
                        holiday: poi.holiday,
                        id: poi.pointage_id,
                        user_id: poi.user_id,
                        isSunday: poi.isSunday,
                        conge: poi.conge,
                        absent: poi.absent,
                        malade: poi.malade,
                        recuperations: poi.recuperations
                    };

                    data.push(his);
                });

                setHistorique(data);
                dataFormat(data);
                setLoading(false);
            } catch (error) {
                // notifyError('Il y a une erreur, veuillez réessayer.');
                toast.warning('Il y a une erreur, veuillez réessayer !')
                return false;
            }
        } else if (selectedAgent === '') {
            // notifyInfo('Vous devez sélectionner un agent.');
            toast.info('Vous devez sélectionner un agent')
        } else if (startDate === '' && endDate === '') {
            // notifyInfo('Vous devez sélectionner la date de fin et la date de début.');
            toast.info('Vous devez sélectionner la date de fin et la date de début')
        } else if (startDate === '') {
            // notifyInfo('Vous devez sélectionner la date de début.');
            toast.info('Vous devez sélectionner la date de début')
        } else if (endDate === '') {
            // notifyInfo('Vous devez sélectionner la date de fin.');
            toast.info('Vous devez sélectionner la date de fin')
        }
    };
    // ? Time Formate Function :
    function formatTimestamp(timestamp) {
        const dateObj = timestamp.toDate();
        const month = dateObj.getMonth() + 1;
        const day = dateObj.getDate();
        const year = dateObj.getFullYear();

        return `${day}/${month}/${year}`;
    };
    // ? checks if today is sunday :
    function isSunday(dateString) {
        // Parse the date string in the format "DD/MM/YYYY"
        const [day, month, year] = dateString.split('/').map(Number);
        const date = new Date(year, month - 1, day); // Month is 0-indexed in JavaScript

        // Sunday is represented by 0 in JavaScript
        return date.getDay() === 0;
    };
    // ? ==========================================================================================

    // ? =========================|| Get Respo Agents Full name and ID ||==========================
    useEffect(() => {
        const handleGetAgents = async () => {
            const result = await getAgnetsForSelect();
            setAgents(result);
        };

        handleGetAgents();
    }, []);
    // ? ==========================================================================================

    // ? ===================================|| Export to CSV ||====================================
    const dataFormat = (d) => {
        let timeCsv = [];
        let affairesCsv = [];
        let extraHrCsv = [];
        let normalHrCsv = [];
        let messageCsv = [];
        let deplacementeCsv = [];
        let transportCsv = [];
        let holidayCsv = [];

        for (let n = 0; n < d.length; n++) {
            timeCsv.push(d[n].date);
            affairesCsv.push(d[n].affaires)
            extraHrCsv.push(d[n].totalExtrawork);
            normalHrCsv.push(d[n].totalNormalwork);
            messageCsv.push(d[n].message);
            deplacementeCsv.push(d[n].deplacementPrice);
            transportCsv.push(d[n].transportPrice);
            holidayCsv.push(d[n].holiday);
        };

        setTime(timeCsv);
        setAffaires(affairesCsv);
        setExtraHr(extraHrCsv);
        setNormaHr(normalHrCsv);
        setMessages(messageCsv);
        setDeplacement(deplacementeCsv);
        setTranssport(transportCsv);
        setHoliday(holidayCsv);
    };
    const dataExport = () => {
        let result = `Time, Affaires, Normal Heures, Heures supplementaires, Message, Deplacement, Transport \n`;
        const dataLenght = historique.length;
        for (let i = 0; i < dataLenght; i++) {
            result += `${time[i]}, ${affaires[i]}, ${normalHr[i]}, ${extraHr[i]}, ${messages[i]}, ${deplacement[i]}, ${transport[i]},\n`;
        }
        return result;
    };

    // Export as XLSX :
    async function exportToExcel() {
        if (historique.length > 0) {
            const data = await dataExport();
            const dataArray = data.split('\n').map(row => row.split(','));

            const worksheet = XLSX.utils.aoa_to_sheet(dataArray);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

            const date = new Date();
            const agentQuery = query(userCollection, where('user_id', '==', selectedAgent));
            const agentSnapshot = await getDocs(agentQuery);
            const agent = agentSnapshot.docs[0]?.data();
            const agentFullName = agent.fullName;

            const formattedDate = date.toLocaleDateString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            }).replace(/\//g, '-');

            const filename = `PTrack _ ${agentFullName.toUpperCase()} _ ${formattedDate}.xlsx`;

            XLSX.writeFile(workbook, filename);
        } else {
            // notifyInfo('Pas de données disponibles.');
            toast.info('Pas de données disponibles !')
        }
    }
    // ? ===========================================================================================

    // ? =====================================|| Pagination ||======================================
    const itemsPerPage = 31; // Set the number of items per page
    const [currentPageData, setCurrentPageData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // Assuming respoAffaires is your data array
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = historique.slice(indexOfFirstItem, indexOfLastItem);
    // ? ===========================================================================================

    // ? ========================|| get the first and last in the month  ||===========================
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");

        return `${year}-${month}-${day}`;
    };

    const getFirstAndLastDayOfMonth = () => {
        try {
            const currentDate = new Date();
            const firstDay = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                1
            );
            const lastDay = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() + 1,
                0
            );

            return { firstDay: formatDate(firstDay), lastDay: formatDate(lastDay) };
        } catch (error) {
            ////console.log("Error in getFirstAndLastDayOfMonth()");
            throw error;
        }
    };

    useEffect(() => {
        const handleGetDate = async () => {
            const result = await getFirstAndLastDayOfMonth();

            setStartDate(result.firstDay);
            setEndDate(result.lastDay);
        };

        handleGetDate();
    }, []);
    // ? ==========================================================================================

    return (
        <div className="flex gap-2 md:gap-3 lg:gap-5 bg-[#F4F9FF]">
            <Sidebar />
            <main className="flex-1 mx-auto py-4 pr-4 h-screen overflow-y-auto">
                {/* Toast */}
                {/* <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    limit={1}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover={false}
                    theme="light"
                /> */}

                <Toaster expand={true} richColors />

                {/* Title */}
                <p className='text-3xl lg:text-4xl font-bold'>Agents Historique</p>

                <div className='flex flex-wrap justify-between items-end'>
                    {/* Agent, Start & End Date */}
                    <div className='w-full lg:w-2/3 mt-10 lg:flex justify-between items-center'>

                        {/* Agent */}
                        <div className='w-full lg:w-1/3 mr-2'>
                            <p className='font-medium'>Agent</p>
                            {/* <Select
                                size='large'
                                className='w-full rounded-md P-2'
                                showSearch
                                placeholder="Select a person"
                                optionFilterProp="children"
                                onChange={handleSelectedAgent}
                                filterOption={filterOption}
                                value={selectedAgent}
                                options={agents}
                            /> */}
                            <Select
                                options={agents}
                                isSearchable={true}
                                onChange={handleSelectedAgent}
                            />
                        </div>

                        {/* Start Date */}
                        <div className='w-full lg:w-1/3 mr-2'>
                            <p className='font-medium'>Date de début</p>
                            <input
                                type='date'
                                className='w-full border border-gray rounded-md py-2 px-4'
                                value={startDate}
                                onChange={handleStartDate}
                            />
                        </div>

                        {/* End Date */}
                        <div className='w-full lg:w-1/3 mr-2'>
                            <p className='font-medium'>Date de fin</p>
                            <input
                                type='date'
                                className='w-full border border-gray rounded-md py-2 px-4'
                                value={endDate}
                                onChange={handleEndDate}
                            />
                        </div>
                    </div>

                    {/* Buttons */}
                    <div className='w-full lg:w-1/3 flex justify-end items-center mt-4 md:mt-2 lg:mt-0'>
                        <button
                            onClick={() => historiqueFunction()}
                            className='flex justify-center items-center bg-primary rounded-md px-4 py-2 text-white mr-2'
                        >
                            <AiOutlineSearch size={22} className='mr-4' /> Recherche
                        </button>
                        <button
                            onClick={() => exportToExcel()}
                            className='flex justify-center items-center bg-primary rounded-md px-4 py-2 text-white'
                        >
                            <PiMicrosoftExcelLogoThin size={22} className='mr-4' /> Exporter
                        </button>
                    </div>
                </div>

                {/* Table */}
                <div>
                    <div className="mt-2">
                        <div className="mb-4 flex flex-col flex-wrap lg:flex-row lg:justify-start lg:items-center">
                            <div className="mb-2 lg:mb-0 mr-8 text-center flex items-center mt-2">
                                <div className="w-6 h-6 bg-gray rounded-full border border-black"></div>
                                <span className="text-sm text-black ml-2 pt-1">
                                    Dimanche
                                </span>
                            </div>

                            <div className="mb-2 lg:mb-0 mr-8 text-center flex items-center mt-2">
                                <div className="w-6 h-6 bg-purple rounded-full border border-black"></div>
                                <span className="text-sm text-black ml-2 pt-1">Congé</span>
                            </div>

                            <div className="mb-2 lg:mb-0 mr-8 text-center flex items-center mt-2">
                                <div className="w-6 h-6 bg-blue rounded-full border border-black"></div>
                                <span className="text-sm text-black ml-2 pt-1">Malade</span>
                            </div>

                            <div className="mb-2 lg:mb-0 mr-8 text-center flex items-center mt-2">
                                <div className="w-6 h-6 bg-softRed rounded-full border border-black"></div>
                                <span className="text-sm text-black ml-2 pt-1">Absent(e)</span>
                            </div>
                        </div>

                        <p className='text-gray text-sm mb-2'>Ici vous pouvez trouver l'historique d'un agent de votre équipe, et vous pouvez exporter les données que vous avez trouvées.</p>

                        <div className="overflow-x-scroll">
                            <table className='w-full rounded-t-lg'>
                                <thead className="bg-primary text-white py-2">
                                    <tr>
                                        <th className="py-3 px-3 text-sm lg:text-base border border-white">
                                            Date
                                        </th>
                                        <th className="py-3 px-3 text-sm lg:text-base border border-white">
                                            Affaires
                                        </th>
                                        <th className="py-3 px-3 text-sm lg:text-base border border-white">
                                            Normal Heures
                                        </th>
                                        <th className="py-3 px-3 text-sm lg:text-base border border-white">
                                            Heures <br />
                                            supplémentaire
                                        </th>
                                        <th className="py-3 px-3 text-sm lg:text-base border border-white">
                                            Message
                                        </th>
                                        <th className="py-3 px-3 text-sm lg:text-base border border-white">
                                            Transport
                                        </th>
                                        <th className="py-3 px-3 text-sm lg:text-base border border-white">
                                            Déplacement
                                        </th>
                                        <th className="py-3 px-3 text-sm lg:text-base border border-white">
                                            Jour férié
                                        </th>
                                        <th className="py-3 px-3 text-sm lg:text-base border border-white">
                                            Action
                                        </th>
                                    </tr>
                                </thead>

                                <tbody className='bg-white'>
                                    {loading ? (
                                        <tr>
                                            <td
                                                colSpan={9}
                                            >
                                                <Skeleton animation="wave" width="98%" height={50} className='mx-auto' />
                                                <Skeleton animation="wave" width="98%" height={50} className='mx-auto' />
                                                <Skeleton animation="wave" width="98%" height={50} className='mx-auto' />
                                                <Skeleton animation="wave" width="98%" height={50} className='mx-auto' />
                                                <Skeleton animation="wave" width="98%" height={50} className='mx-auto' />
                                                <Skeleton animation="wave" width="98%" height={50} className='mx-auto' />
                                            </td>
                                        </tr>
                                    ) :
                                        historique && historique.length > 0 ? (
                                            currentItems?.map((data, index) => (
                                                <tr key={index}
                                                    className={`
                                                    ${isSunday(data?.date) === true ? "border-2 border-black bg-lightgray" : ""} 
                                                    ${data?.absent === true ? 'bg-softRed text-white' : ''}
                                                    ${data?.recuperations === true ? 'bg-lightBlue text-white' : ''}
                                                    ${data?.conge === true ? 'bg-purple text-white' : ''}
                                                    ${data?.malade === true ? 'bg-purple text-white' : ''}
                                                `}
                                                >
                                                    <td
                                                        className={`border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                                                    >
                                                        {data?.date}
                                                    </td>
                                                    <td
                                                        className={`${data?.affaires === "-" ? "bg-light border-x-1" : ""} border border-gray py-2 px-3 text-sm lg:text-base lg:min-w-[600px]`}
                                                        dangerouslySetInnerHTML={{ __html: data?.affaires }}
                                                    ></td>
                                                    <td
                                                        className={`${data?.totalNormalwork === "-" ? "bg-light border-x-1" : ""} border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                                                    >
                                                        {data?.totalNormalwork} h
                                                    </td>
                                                    <td
                                                        className={`${data?.totalExtrawork === "-" ? "bg-light border-x-1" : ""} border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                                                    >
                                                        {data?.totalExtrawork} h
                                                    </td>
                                                    <td
                                                        className={`border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                                                    >
                                                        {data?.message ? data?.message : '-'}
                                                    </td>
                                                    <td
                                                        className={`border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                                                    >
                                                        {data?.transport && data?.transportPrice !== '' ? data?.transportPrice + ' DHs' : null}
                                                        <p className="w-full flex justify-center">
                                                            {data?.transport && data?.transportPrice === '' ? <IoIosCheckmark color="green" size={34} /> : null}
                                                        </p>
                                                    </td>
                                                    <td
                                                        className={`border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                                                    >
                                                        {data?.deplacement && data?.deplacementPrice !== '' ? data?.deplacementPrice + ' DHs' : null}
                                                        <p className="w-full flex justify-center">
                                                            {data?.deplacement && data?.deplacementPrice === '' ? <IoIosCheckmark color="green" size={34} /> : null}
                                                        </p>
                                                    </td>
                                                    <td
                                                        className={`border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                                                    >
                                                        <p className="w-full flex justify-center">
                                                            {data?.holiday ? <IoIosCheckmark color="green" size={34} /> : null}
                                                        </p>
                                                    </td>
                                                    <td
                                                        className={`
                                                        border border-gray text-center py-2 px-3 text-sm lg:text-base 
                                                        ${isSunday(data?.date) === true ? "bg-lightgray" : "border border-gray"} 
                                                        ${data?.absent === true ? 'bg-softRed text-white' : ''}
                                                    `}
                                                    >
                                                        <div className='flex items-center justify-center'>
                                                            <EditHistoriqueModal data={data} onEdit={() => historiqueFunction()} />
                                                            <DeleteHistoriqueModal id={data?.id} date={data?.date} onDelete={() => historiqueFunction()} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="9" className="text-center py-4 text-gray">
                                                    <div className='flex flex-col justify-center items-center mt-4'>
                                                        <BsDatabaseSlash size={30} className='mb-2' />
                                                        <p>Pas de données disponibles.</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>

                        <Pagination
                            itemsPerPage={itemsPerPage}
                            data={historique}
                            onPageChange={handlePageChange}
                            currentPage={currentPage}
                        />
                    </div>
                </div>
            </main>
        </div>
    )
}