import React, { useEffect, useState } from 'react';
// Material UI :
import { Box, IconButton, Modal, Tooltip } from '@mui/material';
// Icons :
import { AiOutlineEdit } from 'react-icons/ai';
// Toast :
import { toast } from 'sonner';
// import 'react-toastify/dist/ReactToastify.css';
// Functions :
import { editAgent } from '../../config/agentsConfig';
// Assets : 
import Loading from '../../assets/images/white-loading.gif';
import { editGroup } from '../../config/groups';

export const EditGroupModal = ({ groupId, ShortName, groupName, onEdit, page, id, searchQuery }) => {
    const [group_id, setGroup_id] = useState('');
    const [shortName, setShortName] = useState('');
    const [group_name, setGroup_name] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const fillData = () => {
        setGroup_id(groupId);
        setShortName(ShortName);
        setGroup_name(groupName);
    };

    // ? ===============================|| Modal Style ||===============================
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [width, setWidth] = useState('50%');
    const [padding, setPadding] = useState(3);

    const currentWidth = window.innerWidth;

    useEffect(() => {
        if (currentWidth <= 500) {
            setWidth('90%');
            setPadding(2);
        }
    }, [currentWidth]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width,
        maxHeight: '95vh',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: padding,
        borderRadius: 3,
    };
    // ? ===============================================================================

    // ? ===============================|| Toast ||===============================
    // const notifySuccess = (message) => toast.success(message, {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: false,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "colored",
    // });
    // const notifyError = (message) => toast.error(message, {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: false,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "colored",
    // });
    // const notifyInfo = (message) => toast.info(message, {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: false,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "colored",
    // });
    // ? ===============================================================================

    // ? ===============================|| Edit Agent ||================================
    const handleEditAgent = async () => {
        try {
            setIsLoading(true);
            if (group_name !== '' && shortName !== '') {
                const result = await editGroup(id, shortName, group_name);
                if (result === 'Changed') {
                    onEdit(); // Refresh table after adding an affair.
                    // notifySuccess('Group modifié avec succès');
                    toast.success('Group modifié avec succès')
                    handleClose();
                    setIsLoading(false);
                }
                else if (result === 'notChanged') {
                    // notifyError('Veuillez réessayer');
                    toast.warning('Il y a un problème, veuillez réessayer !');
                    handleClose();
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
                // handleClose();
                // notifyInfo('Veuillez remplir le formulaire');
                toast.info('Veuillez remplir le formulaire !')
            }
        } catch (error) {
            // notifyError('Veuillez réessayer');
            toast.warning('Il y a un problème, veuillez réessayer !');
            handleClose();
            setIsLoading(false);
        }
    };
    // ? ===============================================================================

    // ? ============================|| Handle Enter Click ||===========================
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleEditAgent();
        }
    };
    // ? ===============================================================================

    // ? ====================|| Refresh data when : Page Change ||======================
    useEffect(() => {
        fillData();
    }, [page, searchQuery]);
    // ? ===============================================================================

    return (
        <div>
            <Tooltip title="Modifier" onClick={handleOpen}>
                <IconButton>
                    <AiOutlineEdit size={20} color='green' />
                </IconButton>
            </Tooltip>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <p className='text-3xl font-bold'>Modifier Group</p>
                    <div className='mt-10'>
                        {/* Group ID */}
                        <div className='mb-4'>
                            <p className='font-medium mb-2'>Group ID</p>
                            <input
                                type='number'
                                placeholder='eg: Nom et prénom'
                                className='border border-gray w-full rounded-md py-2 px-3'
                                value={group_id}
                                onChange={e => setGroup_id(e.target.value)}
                                onKeyPress={handleKeyPress}
                                required
                                disabled
                            />
                        </div>

                        {/* Short Name */}
                        <div className='mb-4'>
                            <p className='font-medium mb-2'>Short Name</p>
                            <input
                                type='text'
                                placeholder='eg: J000000'
                                className='border border-gray w-full rounded-md py-2 px-3'
                                value={shortName}
                                onChange={e => setShortName(e.target.value)}
                                onKeyPress={handleKeyPress}
                                required
                            />
                        </div>

                        {/* Group Name */}
                        <div className='mb-4'>
                            <p className='font-medium mb-2'>Group Name</p>
                            <input
                                type='text'
                                placeholder="eg: XXXX XXXX XXXX"
                                className='border border-gray w-full rounded-md py-2 px-3'
                                value={group_name}
                                onChange={e => setGroup_name(e.target.value)}
                                onKeyPress={handleKeyPress}
                                required
                            />
                        </div>

                        {/* Buttons */}
                        <div className='flex justify-end items-center'>
                            <button
                                onClick={handleClose}
                                className='border border-gray rounded-md py-2 px-4 mr-4'
                            >
                                Annuler
                            </button>
                            <button
                                onClick={() => handleEditAgent()}
                                className='bg-primary text-white rounded-md py-2 px-4'
                            >
                                {
                                    isLoading ? <img src={Loading} alt='' className='w-6' /> : <p>Modifier</p>
                                }
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}
