import React from 'react';
// Components :
import Sidebar from '../components/sidebar/Sidebar';
import ChangePasswordModal from '../components/profilComponents/ChangePasswordModal';
// Image :
import User from '../assets/images/logo.png';
// Toast :
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Profil = () => {
    return (
        <div className="flex gap-2 md:gap-3 lg:gap-5 bg-[#F4F9FF]">
            <Sidebar />
            <main className="flex-1 mx-auto py-4 pr-4 h-screen overflow-y-auto">
                {/* Toast */}
                <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    limit={1}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover={false}
                    theme="light"
                />

                <p className='text-3xl lg:text-4xl font-bold'>Profil</p>

                <div className='flex flex-wrap justify-between items-center mt-10 bg-white shadow-gray shadow-sm rounded-lg p-4'>
                    <div className='flex items-center'>
                        <img src={User} alt='' className='w-[80px] h-[80px] rounded-full mr-4 ' />
                        <div>
                            <p className='text-xl lg:text-2xl font-bold'>{localStorage.getItem('admin_name') ? localStorage.getItem('admin_name').toLocaleUpperCase() : '-'}</p>
                            <p>Administration</p>
                        </div>
                    </div>

                    <ChangePasswordModal />
                </div>
            </main>
        </div>
    )
}