import React, { useEffect, useState } from 'react';
// Material UI :
import { Box, Modal } from '@mui/material';
// Toast :
import { toast } from 'sonner';
// import 'react-toastify/dist/ReactToastify.css';
// Functions :
import { addAgent } from '../../config/agentsConfig';
import { getGroups } from '../../config/groups';
// Assets : 
import Loading from '../../assets/images/white-loading.gif';
import { MdGroupAdd } from 'react-icons/md';
// React-select :
import Select from 'react-select';

export const AddAgentModal = ({ onAdd }) => {
    const [isLoading, setIsLoading] = useState(false);

    const [agentName, setAgentName] = useState('');
    const [agentCin, setAgentCin] = useState('');
    const [agentCnss, setAgentCnss] = useState('');
    const [agentEmail, setAgentEmail] = useState('');
    const [agentPhone, setAgentPhone] = useState('');
    const [agentPricePerDay, setAgentPricePerDay] = useState('');
    const [agentGroupId, setAgentGroupId] = useState('');
    const [groups, setGroups] = useState([]);
    // ? ===============================|| Modal Style ||===============================
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [width, setWidth] = useState('50%');
    const [padding, setPadding] = useState(3);

    const currentWidth = window.innerWidth;

    useEffect(() => {
        if (currentWidth <= 500) {
            setWidth('90%');
            setPadding(2);
        }
    }, [currentWidth]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width,
        maxHeight: '95vh',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: padding,
        borderRadius: 3,
        overflowY: 'scroll'
    };
    // ? ===============================================================================

    // ? ===============================|| Toast ||===============================
    // const notifySuccess = (message) => toast.success(message, {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: false,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "colored",
    // });
    // const notifyInfo = (message) => toast.info(message, {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: false,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "colored",
    // });
    // const notifyError = (message) => toast.error(message, {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: false,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "colored",
    // });
    // ? ===============================================================================

    // ? ================================|| Add Agent ||================================
    const handleAddAgent = async () => {
        try {
            setIsLoading(true);
            if (agentName !== '' && agentEmail !== '') {
                const result = await addAgent(agentName, agentGroupId, agentEmail, agentPricePerDay, agentCin, agentCnss, agentPhone)
                if (result === 'Created') {
                    onAdd(); // Refresh table after adding an affair.
                    // notifySuccess('Agent ajouté avec succès');
                    toast.success('Agent ajouté avec succès')
                    handleClose();

                    // reset inputs :
                    setAgentName('');
                    setAgentCin('');
                    setAgentCnss('');
                    setAgentEmail('');
                    setAgentPhone('');
                    setAgentPricePerDay('');
                    setAgentGroupId('');

                    setIsLoading(false);
                }
                else if (result === 'Exist') {
                    // notifyInfo('l\'email existe déjà, veuillez réessayer');
                    toast.info('l\'email existe déjà, veuillez réessayer !');
                    handleClose();
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
                // handleClose();
                // notifyInfo('Veuillez remplir le formulaire, le nom et l\'adresse e-mail de l\'agent sont importants');
                toast.info('Veuillez remplir le formulaire, le nom et l\'adresse e-mail de l\'agent sont importants');
            }
        } catch (error) {
            // notifyError('Veuillez réessayer !');
            toast.warning('Il y a un problème, veuillez réessayer !')
            handleClose();
            setIsLoading(false);
        }
    };
    // ? ===============================================================================

    // ? ============================|| Handle Enter Click ||===========================
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleAddAgent();
        }
    };
    // ? ===============================================================================

    // ? =============================|| get the shortName ||===========================
    useEffect(() => {
        const handleGetGroups = async () => {
            const result = await getGroups();
            setGroups(result);
        };

        handleGetGroups();
    }, []);
    // ? ===============================================================================

    return (
        <div>
            <button
                className='border border-primary py-2 px-6 rounded-md text-primary hover:bg-primary hover:text-white transition-all'
                onClick={handleOpen}
            >
                Ajouter Agent
            </button>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <p className='text-3xl font-bold'>Ajouter Agent</p>
                    <div className='mt-10'>
                        {/* Full Name */}
                        <div className='mb-4'>
                            <p className='font-medium mb-2'>Nom et prénom</p>
                            <input
                                type='text'
                                placeholder='eg: Nom et prénom'
                                className='border border-gray w-full rounded-md py-2 px-3'
                                value={agentName}
                                onChange={e => setAgentName(e.target.value)}
                                onKeyPress={handleKeyPress}
                                required
                            />
                        </div>

                        {/* CIN */}
                        <div className='mb-4'>
                            <p className='font-medium mb-2'>CIN</p>
                            <input
                                type='text'
                                placeholder='eg: J000000'
                                className='border border-gray w-full rounded-md py-2 px-3'
                                value={agentCin}
                                onChange={e => setAgentCin(e.target.value)}
                                onKeyPress={handleKeyPress}
                            />
                        </div>

                        {/* Code CNSS */}
                        <div className='mb-4'>
                            <p className='font-medium mb-2'>Code CNSS</p>
                            <input
                                type='text'
                                placeholder="eg: XXXX XXXX XXXX"
                                className='border border-gray w-full rounded-md py-2 px-3'
                                value={agentCnss}
                                onChange={e => setAgentCnss(e.target.value)}
                                onKeyPress={handleKeyPress}
                            />
                        </div>

                        {/* Email */}
                        <div className='mb-4'>
                            <p className='font-medium mb-2'>E-mail</p>
                            <input
                                type='text'
                                placeholder="eg: agent@pprime.ma"
                                className='border border-gray w-full rounded-md py-2 px-3'
                                value={agentEmail}
                                onChange={e => setAgentEmail(e.target.value)}
                                onKeyPress={handleKeyPress}
                                required
                            />
                        </div>

                        {/* Téléphone */}
                        <div className='mb-4'>
                            <p className='font-medium mb-2'>Téléphone</p>
                            <input
                                type='text'
                                placeholder="eg: 06 01 02 03 04"
                                className='border border-gray w-full rounded-md py-2 px-3'
                                value={agentPhone}
                                onChange={e => setAgentPhone(e.target.value)}
                                onKeyPress={handleKeyPress}
                            />
                        </div>

                        {/* Price Per Day */}
                        <div className='mb-4'>
                            <p className='font-medium mb-2'>Prix par jour</p>
                            <input
                                type='number'
                                placeholder="eg: 120 Dh"
                                className='border border-gray w-full rounded-md py-2 px-3'
                                value={agentPricePerDay}
                                onChange={e => setAgentPricePerDay(e.target.value)}
                                onKeyPress={handleKeyPress}
                            />
                        </div>

                        {/* Group ID */}
                        <div className='mb-8'>
                            <p className='font-medium mb-2'>Groupe</p>
                            {/* <input
                                type='number'
                                placeholder="eg: 00"
                                className='border border-gray w-full rounded-md py-2 px-3'
                                value={agentGroupId}
                                onChange={e => setAgentGroupId(e.target.value)}
                                onKeyPress={handleKeyPress}
                            /> */}
                            <Select
                                options={groups}
                                isSearchable={true}
                                onChange={e => setAgentGroupId(e.value)}
                            />
                        </div>

                        {/* Buttons */}
                        <div className='flex justify-end items-center'>
                            <button
                                onClick={handleClose}
                                className='border border-gray rounded-md py-2 px-4 mr-4'
                            >
                                Annuler
                            </button>
                            <button
                                onClick={() => handleAddAgent()}
                                className='bg-primary text-white rounded-md py-2 px-4'
                            >
                                {
                                    isLoading ? <img src={Loading} alt='' className='w-6' /> : <p>Ajouter</p>
                                }
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}