import React, { useEffect, useState } from 'react';
// Material UI :
import { Box, Modal, IconButton, Tooltip } from '@mui/material';
// Toast :
import { toast } from 'sonner';
// Icons : 
import { AiOutlineEdit } from 'react-icons/ai';
// Functions :
import { editRespo } from '../../config/responsablesConfig';
import { getGroups } from '../../config/groups';
// Assets : 
import Loading from '../../assets/images/white-loading.gif';
// React-select :
import Select from 'react-select';

export const EditRespoModal = ({ respoId, respoName, respoEmail, respoGroupId, onEdit, page, searchQuery }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [groupId, setGroupId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [groups, setGroups] = useState([]);

    const fillData = () => {
        setName(respoName);
        setEmail(respoEmail);
        setGroupId(respoGroupId);
    };

    // ? ===============================|| Modal Style ||==============================
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [width, setWidth] = useState('50%');
    const [padding, setPadding] = useState(3);

    const currentWidth = window.innerWidth;

    useEffect(() => {
        if (currentWidth <= 500) {
            setWidth('90%');
            setPadding(2);
        }
    }, [currentWidth]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width,
        maxHeight: '95vh',
        background: 'white',
        borderRadius: 3,
        boxShadow: 24,
        p: padding,
    };
    // ? ===============================================================================

    // ? ==================================|| Toast ||==================================
    const notifySuccess = (message) => toast.success(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
    const notifyInfo = (message) => toast.info(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
    // ? ===============================================================================

    // ? ==========================|| Delete Affaire ||=================================
    const handleEditRespo = async () => {
        try {
            setIsLoading(true);
            const result = await editRespo(respoId, name, groupId);
            if (result === 'Changed') {
                onEdit();
                // notifySuccess('Responsable modifié avec succès');
                toast.success('Responsable modifié avec succès')
                handleClose();
                setIsLoading(false);
            } else if (result === 'notChanged') {
                // notifyInfo('Il y a un problème, veuillez réessayer');
                toast.warning('Il y a un problème, veuillez réessayer !');
                handleClose();
                setIsLoading(false);
            };
        } catch (error) {
            // notifyInfo('Il y a un problème, veuillez réessayer');
            toast.warning('Il y a un problème, veuillez réessayer !');
            handleClose();
            setIsLoading(false);
        };
    };
    // ? ===============================================================================

    // ? ============================|| Handle Enter Click ||===========================
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleEditRespo();
        }
    };
    // ? ===============================================================================

    // ? ====================|| Refresh data when : Page Change ||======================
    useEffect(() => {
        fillData();
    }, [page, searchQuery]);
    // ? ===============================================================================

    // ? ==============================|| get Group Name ||=============================
    useEffect(() => {
        const handleGetGroups = async () => {
            try {
                const result = await getGroups();
                setGroups(result);
            } catch (error) {
                console.error('Error fetching groups:', error);
            }
        };

        handleGetGroups();
    }, []);
    // ? ==============================================================================

    return (
        <div>
            <Tooltip title="Modifier" onClick={handleOpen}>
                <IconButton>
                    <AiOutlineEdit size={20} color='green' />
                </IconButton>
            </Tooltip>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <p className='text-3xl font-bold'>Modifier Responsable</p>
                    <div className='mt-10'>
                        {/* Full Name */}
                        <div className='mb-4'>
                            <p className='font-medium mb-2'>Nom et prénom</p>
                            <input
                                type='text'
                                placeholder='eg: Nom et prénom'
                                className='border border-gray w-full rounded-md py-2 px-3'
                                value={name}
                                onChange={e => setName(e.target.value)}
                                onKeyPress={handleKeyPress}
                            />
                        </div>

                        {/* Group ID */}
                        <div className='mb-4'>
                            <p className='font-medium mb-2'>Groupe</p>
                            {/* <select
                                className='border border-gray w-full rounded-md py-2 px-3'
                                value={groupId}
                                onChange={e => setGroupId(e.target.value)}
                            >
                                <option value='' className='text-gray'>Pas maintenant</option>
                                {
                                    groups?.map((group, i) => (
                                        <option key={i} value={group?.value}>{group?.label}</option>
                                    ))
                                }
                            </select> */}

                            <Select
                                options={[
                                    { value: '', label: 'Pas maintenant', className: 'text-gray' },
                                    ...(groups || []).map((group, i) => ({
                                        value: group?.value,
                                        label: group?.label
                                    }))
                                ]}
                                isSearchable={false}
                                value={groups.find(group => group.value === groupId) || ''}
                                onChange={(selectedOption) => setGroupId(selectedOption.value)}
                            />

                        </div>

                        {/* Email */}
                        <div className='mb-8'>
                            <p className='font-medium mb-2'>E-mail</p>
                            <input
                                type='text'
                                placeholder="eg: nom d'affaire"
                                className='border border-gray w-full rounded-md py-2 px-3'
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                onKeyPress={handleKeyPress}
                                disabled
                            />
                        </div>

                        {/* Buttons */}
                        <div className='flex justify-end items-center'>
                            <button
                                onClick={handleClose}
                                className='border border-gray rounded-md py-2 px-4 mr-4'
                            >
                                Annuler
                            </button>
                            <button
                                onClick={() => handleEditRespo()}
                                className='bg-primary text-white rounded-md py-2 px-4'
                            >
                                {
                                    isLoading ? <img src={Loading} alt='' className='w-6' /> : <p>Modifier</p>
                                }
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}