import { initializeApp } from "firebase/app";
import { getFirestore, collection } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyB6VWd4-Znkyw207pOEK3ctZdEnVjApaXs",
    authDomain: "ptrack-7e02a.firebaseapp.com",
    projectId: "ptrack-7e02a",
    storageBucket: "ptrack-7e02a.appspot.com",
    messagingSenderId: "1001599952507",
    appId: "1:1001599952507:web:5a5d137a38699a5ff264de",
    measurementId: "G-DWD6BHXJP4"
};

// Initialize Firebase App (for the client side)
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth();

const pointageCollection = collection(db, "Pointage");
const groupsCollection = collection(db, "Groups");
const affaireCollection = collection(db, "Affaire");
const userCollection = collection(db, "User");
const notificationCollection = collection(db, "Notification");
const deletedItemsCollection = collection(db, "DeletedItems");

export { pointageCollection, groupsCollection, affaireCollection, userCollection, notificationCollection, deletedItemsCollection, auth };