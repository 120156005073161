import { where, query, getDocs, getDoc, doc, setDoc, addDoc, deleteDoc } from "firebase/firestore";
import { userCollection, auth, deletedItemsCollection } from './firebase';
import { createUserWithEmailAndPassword, deleteUser, getAuth } from "firebase/auth";

export const getRespos = async () => {
    try {
        const resposQuery = query(
            userCollection,
            where('role', '==', "Respo")
        );

        const querySnapshot = await getDocs(resposQuery);
        const respos = querySnapshot.docs.map((doc) => doc.data());

        // Sort the respos array by fullName
        respos.sort((a, b) => a.fullName.localeCompare(b.fullName));

        return respos;
    } catch (error) {
        return [];
    }
};

export const createResponosable = async (fullName, email, group_id) => {
    try {
        // Step 1: Create user in Firebase Authentication
        const userCredential = await createUserWithEmailAndPassword(auth, email, 'pprime@2024');
        const user = userCredential.user;
        const userId = user.uid;

        // ! for admin
        // let moreData = {
        //     fullName: userData.fullName,
        //     email: userData.email,
        //     role: userData.role,
        //     password: userData.password,
        //     passwordChanged: userData.passwordChanged,
        //     user_id: userId,
        //     createdBy: 'Devs',
        //     createdDate: new Date(),
        // }

        // ! for agent and respo 

        let data = {
            fullName: fullName,
            email: email,
            role: 'Respo',
            group_id: group_id,
            password: 'pprime@2024',
            passwordChanged: false,
            user_id: userId,
            createdBy: localStorage.getItem('admin_name'),
            createdDate: new Date(),
        };

        // Step 2: Create user document in Firestore with UID as the document ID
        const userDocRef = doc(userCollection, userId);
        await setDoc(userDocRef, data);

        return 'Created';
    } catch (error) {
        return 'Exist';
    }
};

export const editRespo = async (id, name, groupId) => {
    try {
        let newData = {
            fullName: name,
            group_id: groupId,
        };

        await setDoc(doc(userCollection, id), newData, { merge: true });

        return 'Changed';
    } catch (error) {
        return 'notChanged';
    }
}

export const deleteRespo = async (id) => {
    try {
        const adminName = localStorage.getItem('admin_name');

        // Fetch user data from Firestore
        const deletedRespo = doc(userCollection, id);
        const deletedRespoSnapshot = await getDoc(deletedRespo);
        const deletedRespoData = deletedRespoSnapshot.data();

        if (!deletedRespoData) {
            console.error(`User with ID ${id} not found`);
            return 'notDeleted';
        }

        // Create a log for deleted item
        const deletedItemData = {
            group_id: deletedRespoData.group_id,
            respoName: deletedRespoData.fullName,
            email: deletedRespoData.email,
            deletedBy: `Responsable '${deletedRespoData.fullName}' deleted by '${adminName}', ${new Date().toISOString()} `
        };

        // Add the deleted object to the DeletedItems collection
        await addDoc(deletedItemsCollection, deletedItemData);

        // // Delete the user from Firebase Authentication
        // const auth = getAuth();
        // await deleteUser(auth, id);

        // Delete the corresponding document from Firestore
        await deleteDoc(deletedRespo);

        return 'deleted';
    } catch (error) {
        return 'notDeleted';
    }
};

// const userData = {
//     fullName: 'AIT OUHASSAN Moulay tayeb',
//     email: 't.aitouhassan@pprime.ma',
//     role: 'Respo',
//     password: 'code.pprime',
//     group_id: '8',
//     pricePerDay: 188.47,
//     passwordChanged: false
// }

export const getResposForSelect = async () => {
    try {
        const resposQuery = query(
            userCollection,
            where('role', '==', "Respo")
        );

        const querySnapshot = await getDocs(resposQuery);

        const respos = querySnapshot.docs.map((doc) => {
            const { user_id, fullName, group_id } = doc.data();
            const value = `${group_id}-${user_id}`; // Concatenate group_id and userId
            return { label: fullName, value: value };
        });

        return respos;
    } catch (error) {
        return [];
    }
};