// Routes :
import { Routes, Route } from "react-router-dom";
// Pages :
import { Affaires } from "./pages/Affaires";
import { Responsables } from "./pages/Responsables";
import { Agents } from "./pages/Agents";
import { Profil } from "./pages/Profil";
import { Login } from "./pages/Login";
import { AgentsHistorique } from "./pages/AgentsHistorique";
import { SommairePointag } from "./pages/SommairePointag";
import { Paiement } from "./pages/Paiement";
import { Groups } from "./pages/Groups";
import { ProtectedRoutes } from "./components/protectedRoutes/ProtectedRoutes";
import { AffaireHistorique } from "./pages/AffaireHistorique";
import { Pointage } from "./pages/Pointage";

function App() {
    return (
        <Routes>
            <Route path="/" element={<Login />} />

            <Route path="/affaires" element={
                <ProtectedRoutes>
                    <Affaires />
                </ProtectedRoutes>
            } />

            <Route path="/affaires-historique" element={
                <ProtectedRoutes>
                    <AffaireHistorique />
                </ProtectedRoutes>
            } />

            <Route path="/responsables" element={
                <ProtectedRoutes>
                    <Responsables />
                </ProtectedRoutes>
            } />

            <Route path="/agents" element={
                <ProtectedRoutes>
                    <Agents />
                </ProtectedRoutes>
            } />

            <Route path="/profil" element={
                <ProtectedRoutes>
                    <Profil />
                </ProtectedRoutes>
            } />

            <Route path="/agents-historique" element={
                <ProtectedRoutes>
                    <AgentsHistorique />
                </ProtectedRoutes>
            } />

            <Route path="/paiement" element={
                <ProtectedRoutes>
                    <Paiement />
                </ProtectedRoutes>
            } />

            <Route path="/apercu" element={
                <ProtectedRoutes>
                    <SommairePointag />
                </ProtectedRoutes>
            } />

            <Route path="/groups" element={
                <ProtectedRoutes>
                    <Groups />
                </ProtectedRoutes>
            } />

            <Route path="/pointage" element={
                <ProtectedRoutes>
                    <Pointage />
                </ProtectedRoutes>
            } />
        </Routes>
    );
}

export default App;