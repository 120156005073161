import React, { useEffect, useState } from "react";
// Components :
import Sidebar from "../components/sidebar/Sidebar";
// Packages :
import Select from "react-select";
import { Skeleton } from "@mui/material";
// Icons :
import { AiOutlineSearch } from "react-icons/ai";
import { BsDatabaseSlash } from "react-icons/bs";
// Toast :
import { Toaster, toast } from "sonner";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// Functions :
import { getAllAffairesForSelect } from "../config/affairesConfig";
import { getGroups } from "../config/groups";
import { getDataForYear } from "../config/affairHistorique";

export const AffaireHistorique = () => {
  const [affaires, setAffaires] = useState([]);
  const [selectedAffaire, setSelectedAffaire] = useState("");
  const [showedAffaire, setShowedAffaire] = useState("");
  const [data, setData] = useState([]);
  const [projectTotalHours, setProjectTotalHours] = useState([]);
  const [totalPayment, setTotalPayment] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [groups, setGroups] = useState("");
  const [yearsArray, setYearsArray] = useState([]);
  const [showedYears, setShowedYears] = useState([]);

  // ? ==================================|| Get All Affaires ||==================================
  useEffect(() => {
    const handleGetAffaires = async () => {
      const result = await getAllAffairesForSelect();
      setAffaires(result);
    };

    handleGetAffaires();
  }, []);
  // ? ==========================================================================================

  // ? ===============================|| Handle Selected Inputs ||===============================
  const handleSelectedAffaire = (e) => {
    setSelectedAffaire(e.value);
    setShowedAffaire(e);
  };
  //   const handleSelectedYear = (e) => {
  //     setYearsArray(e);
  //     setShowedYear(e);
  //   };
  // ? ==========================================================================================

  // ? ===============================|| Get the Data ||=========================================
  const dataFunction = async () => {
    if (!selectedAffaire) {
      toast.error("Veuillez sélectionner une affaire !");
      return; // Exit the function if the condition is not met
    }

    setLoading(true);

    try {
      const res = await getDataForYear(selectedAffaire, "2024");

      //console.log(res);

      setLoading(false);
      setError("");

      setData(res.dataForYear);
      setProjectTotalHours(res.yearSumup.totalYearHours);
      setTotalPayment(res.yearSumup.totalYearPaymnet);
    } catch (error) {
      setLoading(false);
      setError(
        "Une erreur s'est produite lors de la récupération des données !"
      );
      // Notify user about the error
      toast.error(
        "Une erreur s'est produite lors de la récupération des données !"
      );
    }
  };

  // ? ==========================================================================================

  // ? ==================================|| get the years ||=================================

  function getYearsRange() {
    const currentYear = new Date().getFullYear();
    const yearsArray = [];

    for (let year = 2024; year <= currentYear; year++) {
      yearsArray.push(year);
    }

    return yearsArray;
  }

  useEffect(() => {
    const years = getYearsRange();
    setYearsArray(years);

    const currentYear = new Date().getFullYear();

    setShowedYears({
      label: currentYear,
      value: currentYear,
    });
  }, []);

  const handleSelectedYear = (selectedYears) => {
    setShowedYears(selectedYears);
  };

  // ? ==========================================================================================

  // ? ==================================|| get the shortName ||=================================

  const getShortName = (groupId) => {
    const group = groups.find((group) => group.value === groupId);
    return group ? group.shortName : "-";
  };
  // ? ==========================================================================================

  useEffect(() => {
    const handleGetGroups = async () => {
      const result = await getGroups();
      setGroups(result);
    };

    handleGetGroups();
  }, []);

  return (
    <div className="flex gap-2 md:gap-3 lg:gap-5 bg-[#F4F9FF]">
      <Sidebar />
      <main className="flex-1 mx-auto py-4 pr-4 h-screen overflow-y-scroll">
        {/* <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover={false}
                    theme="colored"
                /> */}

        <Toaster expand={true} richColors />

        <p className="text-3xl lg:text-4xl font-bold">Affaire Historique</p>

        <div className="flex flex-wrap justify-between items-end">
          {/* employé, Start & End Date */}
          <div className="w-full lg:w-2/3 mt-10 lg:flex items-center">
            {/* Affaire */}
            <div className="w-full lg:2/3 mr-2">
              <p className="font-medium">Affaire</p>
              <Select
                className="w-full rounded-md P-2"
                size="large"
                showSearch
                placeholder="Selectionez le projet"
                optionFilterProp="children"
                onChange={handleSelectedAffaire}
                value={showedAffaire}
                options={affaires}
              />
            </div>

            {/* Start Date */}
            <div className="w-full lg:w-1/3 mr-2">
              <p className="font-medium">Année</p>
              <Select
                className="w-full rounded-md P-2"
                size="large"
                showSearch
                placeholder="Sélectionner l'année"
                optionFilterProp="children"
                onChange={handleSelectedYear}
                value={showedYears}
                options={yearsArray.map((year) => ({
                  label: year,
                  value: year,
                }))}
                mode="multiple"
              />
            </div>
          </div>

          {/* Buttons */}
          <div className="w-full lg:w-1/3 flex justify-end items-center mt-4 md:mt-2 lg:mt-0">
            <button
              onClick={() => dataFunction()}
              className="flex justify-center items-center bg-primary rounded-md px-4 py-2 text-white mr-2"
            >
              <AiOutlineSearch size={22} className="mr-4" /> Recherche
            </button>
          </div>
        </div>

        <div>
          <div className="mt-5">
            {data.map((month, i) => (
              <div className="mt-8" key={i}>
                <table className="w-full overflow-hidden rounded-t-lg">
                  <thead className="bg-primary text-white py-2">
                    <tr>
                      <td
                        className="py-3 text-center text-sm lg:text-base border border-white font-bold"
                        colSpan={8}
                      >
                        {month.month}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-3 text-center text-sm lg:text-base border border-white font-bold">
                        gruop d'employé
                      </td>
                      <td className="py-3 text-center text-sm lg:text-base border border-white font-bold">
                        Nom d'employé
                      </td>
                      <td className="py-3 text-center text-sm lg:text-base border border-white font-bold">
                        Total heures normales
                      </td>
                      <td className="py-3 text-center text-sm lg:text-base border border-white font-bold">
                        Total heures supplémentaires
                      </td>
                      <td className="py-3 text-center text-sm lg:text-base border border-white font-bold">
                        Prix total transport
                      </td>
                      <td className="py-3 text-center text-sm lg:text-base border border-white font-bold">
                        Prix total déplacement
                      </td>
                      <td className="py-3 text-center text-sm lg:text-base border border-white font-bold">
                        Total heures
                      </td>
                      <td className="py-3 text-center text-sm lg:text-base border border-white font-bold">
                        Prix
                      </td>
                    </tr>
                  </thead>

                  <tbody className="bg-white">
                    {loading ? (
                      <tr>
                        <td colSpan={8}>
                          <Skeleton
                            animation="wave"
                            width="98%"
                            height={50}
                            className="mx-auto"
                          />
                          <Skeleton
                            animation="wave"
                            width="98%"
                            height={50}
                            className="mx-auto"
                          />
                          <Skeleton
                            animation="wave"
                            width="98%"
                            height={50}
                            className="mx-auto"
                          />
                          <Skeleton
                            animation="wave"
                            width="98%"
                            height={50}
                            className="mx-auto"
                          />
                        </td>
                      </tr>
                    ) : month.data.table.length > 0 ? (
                      month.data.table.map((d, i) => (
                        <tr key={i}>
                          <td
                            className={`border border-gray text-center py-2 px-3 text-sm lg:text-base font-bold`}
                          >
                            {getShortName(d?.group_id)}
                          </td>
                          <td
                            className={`border border-gray text-center py-2 px-3 text-sm lg:text-base font-bold`}
                          >
                            {d?.fullName ? d?.fullName : "-"}
                          </td>
                          <td
                            className={`border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                          >
                            {d?.totalNormalwork ? d?.totalNormalwork : "-"}
                          </td>
                          <td
                            className={`border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                          >
                            {d?.totalExtrawork ? d?.totalExtrawork : "-"}
                          </td>
                          <td
                            className={`border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                          >
                            {d?.totalTrasportPrice
                              ? d?.totalTrasportPrice
                              : "-"}
                          </td>
                          <td
                            className={`border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                          >
                            {d?.totalDeplacementPrice
                              ? d?.totalDeplacementPrice
                              : "-"}
                          </td>
                          <td
                            className={`border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                          >
                            {Number(d?.totalHours).toFixed(2)
                              ? d?.totalHours
                              : "-"}
                          </td>
                          <td
                            className={`border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                          >
                            {d?.price}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="8" className="text-center py-4 text-gray">
                          <div className="flex flex-col justify-center items-center mt-4">
                            <BsDatabaseSlash size={30} className="mb-2" />
                            <p>Pas de données disponibles.</p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <table className="w-full overflow-hidden rounded-t-lg rounded-b-lg">
                  {loading ? (
                    <tr>
                      <td colSpan={7}>
                        <div className="flex items-center">
                          <Skeleton
                            animation="wave"
                            width="74%"
                            height={50}
                            className="mx-auto"
                          />
                          <Skeleton
                            animation="wave"
                            width="24%"
                            height={50}
                            className="mx-auto"
                          />
                        </div>
                        <div className="flex items-center">
                          <Skeleton
                            animation="wave"
                            width="74%"
                            height={50}
                            className="mx-auto"
                          />
                          <Skeleton
                            animation="wave"
                            width="24%"
                            height={50}
                            className="mx-auto"
                          />
                        </div>
                      </td>
                    </tr>
                  ) : data.length > 0 ? (
                    <thead>
                      <tr className="text-center">
                        <th className="py-3 text-center text-sm lg:text-base border border-white font-bold bg-primary text-white">
                          Nombre d'heures du projet ce mois-ci{" "}
                        </th>
                        <td className="bg-white font-bold text-lg">
                          {month.data.totalMonthHours} H
                        </td>
                      </tr>
                      <tr className="text-center">
                        <th className="py-3 text-center text-sm lg:text-base border border-white font-bold bg-primary text-white">
                          Les charges du projet ce mois-ci{" "}
                        </th>
                        <td className="bg-white font-bold text-lg">
                          {Number(month.data.totalMonthPayment).toFixed(2)} DHs
                        </td>
                      </tr>
                    </thead>
                  ) : null}
                </table>
              </div>
            ))}
            <div className="mt-14">
              <table className="w-full overflow-hidden rounded-t-lg rounded-b-lg">
                {loading ? (
                  <tr>
                    <td colSpan={7}>
                      <div className="flex items-center">
                        <Skeleton
                          animation="wave"
                          width="74%"
                          height={50}
                          className="mx-auto"
                        />
                        <Skeleton
                          animation="wave"
                          width="24%"
                          height={50}
                          className="mx-auto"
                        />
                      </div>
                      <div className="flex items-center">
                        <Skeleton
                          animation="wave"
                          width="74%"
                          height={50}
                          className="mx-auto"
                        />
                        <Skeleton
                          animation="wave"
                          width="24%"
                          height={50}
                          className="mx-auto"
                        />
                      </div>
                    </td>
                  </tr>
                ) : data.length > 0 ? (
                  <thead>
                    <tr className="text-center">
                      <th className="py-3 text-center text-sm lg:text-base border border-white font-bold bg-primary text-white">
                        Nombre d'heures du projet
                      </th>
                      <td className="bg-white font-bold text-lg">
                        {projectTotalHours} H
                      </td>
                    </tr>
                    <tr className="text-center">
                      <th className="py-3 text-center text-sm lg:text-base border border-white font-bold bg-primary text-white">
                        Les charges du projet
                      </th>
                      <td className="bg-white font-bold text-lg">
                        {totalPayment.toFixed(2)} DHs
                      </td>
                    </tr>
                  </thead>
                ) : (
                  <thead>
                    <tr className="text-center">
                      <th className="max-w-[50px] py-3 text-center text-sm lg:text-base border border-white font-bold bg-primary text-white">
                        Nombre d'heures du projet
                      </th>
                      <td className="bg-white font-bold text-lg">-</td>
                    </tr>
                    <tr className="text-center">
                      <th className="max-w-[50px] py-3 text-center text-sm lg:text-base border border-white font-bold bg-primary text-white">
                        Les charges du projet
                      </th>
                      <td className="bg-white font-bold text-lg">-</td>
                    </tr>
                  </thead>
                )}
              </table>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
