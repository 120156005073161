import React, { useEffect, useState } from 'react';
// Material UI :
import { Box, Modal, IconButton, Tooltip } from '@mui/material';
// Icons :
import { AiOutlineEdit } from 'react-icons/ai';
// Toast :
import { toast } from 'sonner';
import 'react-toastify/dist/ReactToastify.css';
// Functions :
import { editAffaire } from '../../config/affairesConfig';
// Assets : 
import Loading from '../../assets/images/white-loading.gif';

export const EditAffaireModal = ({ id, name, code, onEdit, page, searchQuery }) => {
    const [codeAffaire, setCodeAffaire] = useState('');
    const [nameAffaire, setNameAffaire] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState('');

    const fillData = () => {
        setCodeAffaire(code);
        setNameAffaire(name);
    };

    // ? ===============================|| Modal Style ||===============================
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [width, setWidth] = useState('50%');
    const [padding, setPadding] = useState(3);

    const currentWidth = window.innerWidth;

    useEffect(() => {
        if (currentWidth <= 500) {
            setWidth('90%');
            setPadding(2);
        }
    }, [currentWidth]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width,
        maxHeight: '95vh',
        background: 'white',
        borderRadius: 3,
        boxShadow: 24,
        p: padding,
    };
    // ? ===============================================================================

    // ? ==========================|| Toast ||==========================================
    const notifySuccess = () => toast.success('L\'affaire a été modifier avec succès !', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
    const notifyError = () => toast.error('Il y a un problème, réessayez s\'il vous plaît', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
    // ? ===============================================================================

    // ? ==============================|| Edit Affaire ||===============================
    const handleEditAffaire = async () => {
        try {
            setIsLoading(true);
            await editAffaire(id, codeAffaire, nameAffaire);
            onEdit(); // Refresh table after editing an affair
            // notifySuccess();
            toast.success('L\'affaire a été modifier avec succès')
            handleClose();
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            // notifyError();
            toast.warning("Il y a un problème, réessayez s'il vous plaît !");
            handleClose();
        }
    };
    // ? ===============================================================================

    // ? ============================|| Handle Enter Click ||===========================
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleEditAffaire();
        }
    };
    // ? ===============================================================================

    // ? ====================|| Refresh data when : Page Change ||======================
    useEffect(() => {
        fillData();
    }, [page, searchQuery]);
    // ? ===============================================================================

    return (
        <div>
            <Tooltip title="Modifier" onClick={handleOpen}>
                <IconButton>
                    <AiOutlineEdit size={20} color='green' />
                </IconButton>
            </Tooltip>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <p className='text-3xl font-bold'>Modifier Affaire</p>
                    <div className='mt-10'>
                        {/* Date Creation */}
                        <div className='mb-4'>
                            <p className='font-medium mb-2'>La Date</p>
                            <input
                                type='date'
                                className='border border-gray w-full rounded-md py-2 px-3'
                                value={selectedDate}
                                onChange={e => setSelectedDate(e.target.value)}
                                onKeyPress={handleKeyPress}
                            />
                        </div>

                        {/* Code Affaire */}
                        <div className='mb-4'>
                            <p className='font-medium mb-2'>Code Affaire</p>
                            <input
                                type='text'
                                placeholder='eg: 123456'
                                className='border border-gray w-full rounded-md py-2 px-3'
                                value={codeAffaire}
                                onChange={e => setCodeAffaire(e.target.value)}
                                onKeyPress={handleKeyPress}
                            />
                        </div>

                        {/* Name Affaire */}
                        <div className='mb-8'>
                            <p className='font-medium mb-2'>Nom d'Affaire</p>
                            <input
                                type='text'
                                placeholder="eg: nom d'affaire"
                                className='border border-gray w-full rounded-md py-2 px-3'
                                value={nameAffaire}
                                onChange={e => setNameAffaire(e.target.value)}
                                onKeyPress={handleKeyPress}
                            />
                        </div>

                        {/* Buttons */}
                        <div className='flex justify-end items-center'>
                            <button
                                className='border border-gray rounded-md py-2 px-4 mr-4'
                                onClick={handleClose}
                            >
                                Annuler
                            </button>

                            <button
                                className='bg-primary text-white rounded-md py-2 px-4'
                                onClick={() => handleEditAffaire()}
                            >
                                {
                                    isLoading ? <img src={Loading} alt='' className='w-6' /> : <p>Modifier</p>
                                }
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}