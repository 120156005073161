import React, { useEffect, useState } from "react";
// ? Packages :
import { Skeleton } from "@mui/material";
// Icons :
import { BsDatabaseSlash } from "react-icons/bs";
// Toast :
import { Toaster } from 'sonner'
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// ? Components :
import Sidebar from "../components/sidebar/Sidebar";
import { AddAgentModal } from "../components/agentsComponents/AddAgentModal";
import { DeleteAgentModal } from "../components/agentsComponents/DeleteAgentModal";
import { EditAgentModal } from "../components/agentsComponents/EditAgentModal";
import SearchBar from "../components/searchBar/SearchBar";
// ? Functions :
import { Pagination } from "../components/paginationComponent/Pagination";
import { getAgents } from "../config/agentsConfig";
import { getGroups } from "../config/groups";

export const Agents = () => {
    const [agents, setAgents] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredData, setFilteredData] = useState(agents);
    const [groups, setGroups] = useState("");
    const [loading, setLoading] = useState(false);

    // ? =============================|| Get All Agents ||=============================
    const handleGetAgents = async () => {
        setLoading(true);
        const result = await getAgents();
        setLoading(false);
        setAgents(result);
        setFilteredData(result);
    };

    useEffect(() => {
        handleGetAgents();
    }, []);

    useEffect(() => {
        const handleGetGroups = async () => {
            const result = await getGroups();
            setGroups(result);
        };

        handleGetGroups();
    }, []);
    // ? ==============================================================================

    // ? ============================|| get the shortName ||===========================
    const getShortName = (groupId) => {
        const group = groups.find((group) => group.value === groupId);
        return group ? group.shortName : "-";
    };
    // ? ==============================================================================

    // ? ===============================|| Pagination ||===============================
    const itemsPerPage = 10; // Set the number of items per page
    const [currentPage, setCurrentPage] = useState(1);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // Assuming respoAffaires is your data array
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    // ? ==============================================================================

    // ? =========================|| Handle Search Function ||=========================
    const handleSearch = (searchTerm) => {
        setSearchQuery(searchTerm);

        // If the search query is empty, display all agents
        if (searchTerm.trim() === "") {
            setFilteredData(agents);
        } else {
            // Otherwise, filter the data based on the search term
            const searchTermLower = searchTerm.toLowerCase();

            const filteredResults = agents.filter((agent) => {
                const groupID = agent.group_id;
                const shortName = getShortName(groupID);

                // Check for an exact match in the agent name or group short name
                return (
                    agent.fullName.toLowerCase().includes(searchTermLower) ||
                    shortName.toLowerCase().includes(searchTermLower)
                );
            });

            setCurrentPage(1);
            setFilteredData(filteredResults);
        }
    };
    // ? ==============================================================================

    return (
        <div className="flex gap-2 md:gap-3 lg:gap-5 bg-[#F4F9FF]">
            <Sidebar />
            <main className="flex-1 mx-auto py-4 pr-4 h-screen overflow-y-auto">
                {/* <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover={false}
                    theme="colored"
                /> */}

                <Toaster expand={true} richColors />

                <div className="flex justify-between items-center mb-10">
                    <p className="text-3xl lg:text-4xl font-bold">Agents</p>
                    <AddAgentModal onAdd={() => handleGetAgents()} />
                </div>

                {/* Search Bar */}
                <SearchBar
                    onSearch={handleSearch}
                    placeholder="Rechercher par nom d'agent ou par groupe"
                />

                <div>
                    <p className="text-gray text-sm mb-2">
                        Ici vous pouvez trouver les agents et vous pouvez ajouter, modifier
                        et supprimer un agent.
                    </p>
                    <table className="w-full overflow-hidden rounded-t-lg">
                        <thead className="bg-primary text-white py-2">
                            <tr>
                                <td className="py-3 text-center text-sm lg:text-base border border-white font-bold">
                                    Groupe
                                </td>
                                <td className="py-3 text-center text-sm lg:text-base border border-white font-bold">
                                    Nom et prénom
                                </td>
                                <td className="py-3 text-center text-sm lg:text-base border border-white font-bold">
                                    CIN
                                </td>
                                <td className="py-3 text-center text-sm lg:text-base border border-white font-bold">
                                    Code CNSS
                                </td>
                                <td className="py-3 text-center text-sm lg:text-base border border-white font-bold">
                                    E-mail
                                </td>
                                <td className="py-3 text-center text-sm lg:text-base border border-white font-bold">
                                    Téléphone
                                </td>
                                <td className="py-3 text-center text-sm lg:text-base border border-white font-bold">
                                    Prix par jour
                                </td>
                                <td className="py-3 text-center text-sm lg:text-base border border-white font-bold">
                                    Action
                                </td>
                            </tr>
                        </thead>

                        <tbody className="bg-white">
                            {loading ? (
                                <tr>
                                    <td
                                        colSpan={8}
                                    >
                                        <Skeleton animation="wave" width="98%" height={50} className='mx-auto' />
                                        <Skeleton animation="wave" width="98%" height={50} className='mx-auto' />
                                        <Skeleton animation="wave" width="98%" height={50} className='mx-auto' />
                                        <Skeleton animation="wave" width="98%" height={50} className='mx-auto' />
                                        <Skeleton animation="wave" width="98%" height={50} className='mx-auto' />
                                        <Skeleton animation="wave" width="98%" height={50} className='mx-auto' />
                                    </td>
                                </tr>
                            ) :
                                currentItems.length > 0 ? (
                                    currentItems.map((agent, i) => (
                                        <tr key={i}>
                                            <td
                                                className={`border border-gray text-center py-2 px-3 text-sm lg:text-base font-bold`}
                                            >
                                                {getShortName(agent?.group_id)}
                                            </td>
                                            <td
                                                className={`border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                                            >
                                                {agent?.fullName ? agent?.fullName : "-"}
                                            </td>
                                            <td
                                                className={`border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                                            >
                                                {agent?.cin ? agent?.cin : "-"}
                                            </td>
                                            <td
                                                className={`border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                                            >
                                                {agent?.cnss ? agent?.cnss : "-"}
                                            </td>
                                            <td
                                                className={`border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                                            >
                                                {agent?.email ? agent?.email : "-"}
                                            </td>
                                            <td
                                                className={`border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                                            >
                                                {agent?.phone ? agent?.phone : "-"}
                                            </td>
                                            <td
                                                className={`border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                                            >
                                                {agent?.pricePerDay ? agent?.pricePerDay + ' DHs' : "-"}
                                            </td>
                                            <td
                                                className={`border border-gray text-center py-2 px-3 text-sm lg:text-base`}
                                            >
                                                <div className="flex items-center justify-center">
                                                    <EditAgentModal
                                                        agentId={agent?.user_id ? agent?.user_id : "-"}
                                                        name={agent?.fullName ? agent?.fullName : "-"}
                                                        cin={agent?.cin ? agent?.cin : "-"}
                                                        cnss={agent?.cnss ? agent?.cnss : "-"}
                                                        email={agent?.email ? agent?.email : "-"}
                                                        phone={agent?.phone ? agent?.phone : "-"}
                                                        pricePerDay={agent?.pricePerDay ? agent?.pricePerDay : "-"}
                                                        groupId={agent?.group_id ? agent?.group_id : "-"}
                                                        page={currentPage}
                                                        searchQuery={searchQuery}
                                                        onEdit={() => handleGetAgents()}
                                                    />
                                                    <DeleteAgentModal
                                                        agentId={agent?.user_id ? agent?.user_id : "-"}
                                                        name={agent?.fullName ? agent?.fullName : "-"}
                                                        onDelete={() => handleGetAgents()}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="7" className="text-center py-4 text-gray">
                                            <div className="flex flex-col justify-center items-center mt-4">
                                                <BsDatabaseSlash size={30} className="mb-2" />
                                                <p>Pas de données disponibles.</p>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
                <Pagination
                    itemsPerPage={itemsPerPage}
                    data={filteredData}
                    onPageChange={handlePageChange}
                    currentPage={currentPage}
                />
            </main>
        </div>
    );
};
